import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { ShipmentService } from '@services/shipment';
import { leaveEvent, listenChannel } from '@services/socket';
import { IDriver } from '@store/driver';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

const DriverModal: FC = () => {
	const [driver, setDriver] = useState<IDriver | null>(null);
	const { shift, operator, notice, driverModal, setDriverModal, setLoading } = useStore();

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.driver', operator.id);
	};

	const close = (): void => {
		leave();
		setDriver(null);
		setDriverModal(false);
	};

	const submit = (id: number): void => {
		setLoading(true);

		ShipmentService.postReleaseDriver(id)
			.then(close)
			.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (shift && window.Echo && operator?.id && driverModal) {
			listenChannel(
				({ driver_id: id, owner }: { driver_id: string; owner: string }) => {
					setDriver({ id: +id, isMercenary: owner === 'mercenary_courier' ? 1 : 0 });
					submit(+id);
				},
				'.routing.driver',
				operator.id
			);
		}

		return leave;
	}, [driverModal]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.modal__body}
			title={null}
			okText="Продолжить"
			cancelText="Закрыть"
			open={driverModal}
			onCancel={close}
			onOk={() => driver?.id && submit(driver.id)}
			okButtonProps={{ disabled: !driver?.id }}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			<h3 className={styles.modal__title}>Введите id Водителя</h3>
			<InputNumber
				className={styles.input}
				value={driver?.id}
				setValue={(value) => setDriver({ id: value, isMercenary: 0 })}
			/>
		</Modal>
	);
};

export default observer(DriverModal);
