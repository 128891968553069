/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, union, array, string, literal, number } = types;

const RoutingSpace = model('spaces', {
	id: number,
	type: number,
	is_scanned: union(literal(0), literal(1)),
	number: string,
	routing_items_count: number,
	status: number,
});

const RoutingInfoByDriver = model('routing-info-by-driver', {
	id: array(number),
	counts: model('counts', {
		bags: number,
		postals: number,
		boxes: number,
		goods: number,
		money_bags: number,
		total: number,
	}),
	routing_spaces: array(RoutingSpace),
});

export interface IRoutingInfoByDriver extends SnapshotIn<typeof RoutingInfoByDriver> {}
export default RoutingInfoByDriver;
