import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { IItem } from '@store/acceptance/postal/cargo-spaces';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { sortRYG } from '../../../../../../../../../../../utils/sortRYG';
import styles from './index.module.scss';

const ItemsTable: FC = () => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	const {
		acceptance: {
			postal: {
				postalCargoSpaces: { getModalItemsShared },
			},
		},
	} = useStore();

	if (!getModalItemsShared?.length) return <NoData text="Нет отсканированных посылок" />;

	const columns: ColumnsType<IItem> = [
		{
			title: <span>Номер посылки</span>,
			dataIndex: 'code',
			className: styles.cell,
			render: (_, { number, is_deleted }) => (
				<>
					<div>{number}</div>
					{!!is_deleted && <div>Удалено из грузоместа</div>}
				</>
			),
		},
		{
			title: <span>{getModalItemsShared?.some((el) => el.store_place_item_full_name) ? 'Номер ячейки' : null}</span>,
			dataIndex: 'cell',
			className: styles.cell,
			render: (_, { store_place_item_full_name }) => <span>{store_place_item_full_name || ''}</span>,
		},
	];

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={getModalItemsShared
				.sort((a, b) => {
					const colorComparison = sortRYG([
						[[3, 4, 5].includes(Number(a.status)), [3, 4, 5].includes(Number(b.status))],
						[a.status === 2, b.status === 2],
						[!!a.store_place_item_full_name && !a.is_deleted, !!b.store_place_item_full_name && !b.is_deleted],
					]);
					return colorComparison;
				})
				.map((item) => ({ ...item, key: item.id }))}
			onRow={({ is_scanned, is_deleted, store_place_item_full_name, message_error, status }) => ({
				className: cn(styles.row, {
					[styles.row__check]: is_scanned || store_place_item_full_name,
					[styles.row__deleted]: is_deleted,
					[styles.row__warning]: message_error && status === 2,
					[styles.row__error]: message_error && [3, 4, 5].includes(Number(status)),
				}),
			})}
			expandable={{
				expandedRowClassName: ({ message_error, status }) =>
					cn(styles.row, styles.row__expand, {
						[styles.warning]: message_error && status === 2,
						[styles.error]: message_error && [3, 4, 5].includes(Number(status)),
					}),
				showExpandColumn: false,
				expandedRowRender: ({ message_error }) => <span>{message_error}</span>,
				rowExpandable: ({ message_error, key }) => {
					if (message_error && key && !expandedRowKeys.includes(key)) setExpandedRowKeys((value) => [...value, key]);
					return !!message_error;
				},
				expandedRowKeys,
			}}
			onHeaderRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
		/>
	);
};

export default observer(ItemsTable);
