import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const InfoModalAttachCell: FC = () => {
	const {
		acceptance: { modalInfoAttachCell, closeModalInfoAttachCell },
	} = useStore();

	return (
		<>
			<Modal
				onCancel={closeModalInfoAttachCell}
				open={!!modalInfoAttachCell}
				classNameModal={styles.modal}
				closeIcon={false}
				centered
				destroyOnClose
			>
				<Icon fontSize={50} imageName="Info" className={styles.icon} />
				<div className={styles.text}>{modalInfoAttachCell || ''}</div>
				<Button className={styles.button} onClick={closeModalInfoAttachCell}>
					Закрыть
				</Button>
			</Modal>
		</>
	);
};

export default observer(InfoModalAttachCell);
