import Tabs from '@components/tabs';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Order } from '@models/index';
import OrderService from '@services/order';
import { PostalService } from '@services/postal';
import { Postal } from '@store/visits/postal';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { checkPostalIcon } from 'utils/checkPostalIcon';

import Modal from '../modal';
import OrderInfo from './components/order-info';
import PostalInfo from './components/postal-info';
import PostalTracking from './components/tracking';
import styles from './index.module.scss';

type ModalType = {
	type: 'postal' | 'order';
	id: number | null;
	isExtended?: boolean;
	onClose: () => void;
};

const PostalInfoModal: FC<ModalType> = ({ type, id, isExtended, onClose }) => {
	const { notice } = useStore();
	const [postal, setPostal] = useState<Postal | null>(null);
	const [order, setOrder] = useState<Order | null>(null);
	const [tab, setTab] = useState<'info' | 'tracking'>('info');

	const getData = async (id: number, type: 'postal' | 'order'): Promise<void> => {
		try {
			if (type === 'order') {
				const res = await OrderService.getOrder(id);
				setOrder(res.data);
			} else {
				const res = await PostalService.getPostalInfo(id);
				setPostal(res.data);
			}
		} catch (err: any) {
			onClose && onClose();
			notice.setNotice({ message: err?.error || err?.message || '' });
		}
	};

	useEffect(() => {
		if (id) {
			setTab('info');
			getData(id, type);
		} else {
			setPostal(null);
			setOrder(null);
		}
	}, [id, type]);

	const tabs = [{ key: 'info', label: 'Инфо' }];

	if (type === 'postal') tabs.push({ key: 'tracking', label: 'Трекинг' });

	return (
		<Modal
			title={
				postal ? (
					<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
						<Icon
							imageName={postal.type > 1 ? 'Payments' : checkPostalIcon(postal.market_status, postal.agreement_type_id)}
						/>
						{postal.number}
					</div>
				) : order ? (
					<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
						<Icon imageName="ShoppingBag" /> {order.market_id}
					</div>
				) : (
					'Загрузка'
				)
			}
			footer={null}
			open={!!postal || !!order}
			width={1085}
			onCancel={onClose}
			className={classNames(styles.body, { [styles.body__tracking]: tab === 'tracking' })}
			centered
		>
			<Tabs items={tabs} activeKey={tab} onChange={(key) => setTab(key as typeof tab)} style={{ marginBottom: 24 }} />
			{(tab === 'info' &&
				(type !== 'order' ? (
					<PostalInfo postal={postal} isExtended={isExtended} />
				) : (
					<OrderInfo order={order} isExtended={isExtended} />
				))) ||
				(tab === 'tracking' && type !== 'order' && id && <PostalTracking postalId={id} />)}
		</Modal>
	);
};

export default PostalInfoModal;
