import colors from '@config/colors';
import { ConfigProvider, Select as AntSelect } from 'antd';
import cn from 'classnames';
import { CSSProperties, FC, useState } from 'react';

import styles from './index.module.scss';

export type SelectProps = Parameters<typeof AntSelect>[0] & {
	required?: boolean;
	hideArrow?: boolean;
	isActive?: boolean;
};

const Select: FC<SelectProps & { wrapperStyle?: CSSProperties }> = ({
	className,
	children,
	placeholder,
	value,
	onSelect,
	required,
	hideArrow,
	isActive = false,
	wrapperStyle,
	...props
}) => {
	const [inputValue, setInputValue] = useState('');

	return (
		<ConfigProvider
			theme={{
				token: {
					controlHeight: 38,
					borderRadius: 2,
					colorBgContainerDisabled: '#fff',
					colorTextDisabled: colors.grey1,
				},
			}}
		>
			<div className={styles.wrap} style={wrapperStyle}>
				<AntSelect
					{...props}
					className={cn(className, styles.select)}
					value={value}
					popupClassName={styles.popup}
					onSelect={(value, option) => {
						onSelect && onSelect(value, option);
					}}
					dropdownStyle={{ padding: 0 }}
					notFoundContent={
						props.notFoundContent !== undefined ? (
							props.notFoundContent
						) : (
							<div style={{ textAlign: 'center', color: colors.grey1, padding: 16 }}>Ничего не найдено</div>
						)
					}
					suffixIcon={props.disabled || hideArrow ? null : undefined}
					onSearch={(value) => setInputValue(value)}
					showSearch
				>
					{children}
				</AntSelect>
				{placeholder && (
					<span
						className={cn(styles.label, {
							[styles.label__active]: value || inputValue || isActive,
							[styles.label__disabled]: props.disabled,
						})}
					>
						<span style={{ position: 'relative', zIndex: 1 }}>
							{placeholder} {required && <span style={{ color: colors.red }}>*</span>}
						</span>
					</span>
				)}
			</div>
		</ConfigProvider>
	);
};

export default Select;
