import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const {
		shipment: {
			post: {
				consolidation: { modalGoodsTable, setModalQr },
			},
		},
	} = useStore();

	return (
		<header className={styles.header}>
			<span className={styles.counter}>
				<span className={styles.counter__current}>
					{modalGoodsTable?.isStatic
						? (modalGoodsTable.space?.items.length || 0) - (modalGoodsTable.space?.surplusCount || 0)
						: modalGoodsTable?.space?.scannedCount || 0}
					{modalGoodsTable?.space?.surplusCount ? <span>+{modalGoodsTable.space.surplusCount}</span> : null}
				</span>
				<span>/{modalGoodsTable?.space?.count || 0}</span>
			</span>
			{modalGoodsTable?.isStatic && (
				<Button onClick={() => setModalQr(modalGoodsTable.space?.id || null)}>Печать QR-кода</Button>
			)}
		</header>
	);
};

export default observer(Header);
