/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import { ShipmentService } from '@services/shipment';
import Driver, { IDriver } from '@store/driver';
import RoutingInfoByDriver from '@store/routing-info-by-driver';
import { cast, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';
import { removeMaskEmall } from 'utils/removeMaskEmall';

import { RootStore } from '..';

const { model, maybeNull, union, literal, array, string, number, optional } = types;

const Sheet = model('sheet', {
	id: number,
	status: number,
	status_name: string,
	has_emall: union(literal(0), literal(1)),
	has_postal: union(literal(0), literal(1)),
	routing_spaces_count: number,
	date: maybeNull(string),
	driver_fio: maybeNull(string),
	is_surplus: maybeNull(union(literal(0), literal(1))),
});

export const SheetByShipment = model('routing-info-by-driver', {
	show: false,
	spaces: array(Sheet),
	driver: maybeNull(Driver),
}).actions((self) => ({
	setSpaces(spaces: Sheet[]) {
		self.spaces = cast(spaces);
		self.show = true;
	},
	setDriver(driver: IDriver | null) {
		self.driver = driver;
	},
	setShow(value: boolean) {
		self.show = value;
	},
	confirm: flow(function* ({ id, isSkip }: { id?: number; isSkip?: boolean }) {
		const root = getRoot<typeof RootStore>(self);
		if (!self.driver?.id) return;
		root.setLoading(true);

		try {
			if (isSkip) {
				yield ShipmentService.postReleaseDriver(self.driver.id);
			} else {
				if (!id) return;
				yield ShipmentService.postDriver(id, { driver_market_id: self.driver.id });
			}
			root.acceptance.routeSheet.closeSheetByShipment();
			root.acceptance.routeSheet.setIsModalInfoCargoSpace(true);
			root.acceptance.loadSpaces();
		} catch (err: any) {
			root.notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			root.setLoading(false);
		}
	}),
}));

const RouteSheet = model('route-sheet', {
	list: maybeNull(array(Sheet)),
	showOld: optional(union(literal(0), literal(1)), 0),
	isModalInfoView: false,
	isModalInfoCargoSpace: false,
	modalInfoByDriver: maybeNull(RoutingInfoByDriver),
	modalSheetByShipment: maybeNull(SheetByShipment),
	isOpenModalContinueAcceptance: false,
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);

		return {
			loadList: flow(function* () {
				root.setLoading(true);
				try {
					const res: { data: Sheet[] } = yield AcceptanceService.getLists(self.showOld);
					self.list = cast(res.data);
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			setShowOld(value: boolean) {
				self.list = null;
				self.showOld = value ? 1 : 0;
				root.acceptance.loadHeaderInfo();
			},
			setIsModalInfoView(value: boolean) {
				self.isModalInfoView = value;
			},
			setIsModalInfoCargoSpace(value: boolean) {
				self.isModalInfoCargoSpace = value;
			},
			setIsOpenModalContinueAcceptance(value: boolean) {
				self.isOpenModalContinueAcceptance = value;
			},
			handleScanner(evt: KeyboardEvent) {
				const {
					modalInfo,
					routeSheet: { loadInfoByDriver, modalInfoByDriver },
				} = root.acceptance;

				root.scanner.handleScanner(evt, (value) => {
					if (root.acceptance.routeSheet.isOpenModalContinueAcceptance) {
						root.acceptance.scanContinueAcceptance(removeMaskEmall(value));
						return;
					}

					let data: string | number | { type: string; owner: string; data: { driver_id: number } };

					try {
						data = JSON.parse(value);
					} catch {
						data = value;
					}

					if (typeof data === 'object') {
						if (!modalInfoByDriver?.id) {
							const driverId = data.data.driver_id;
							loadInfoByDriver({ id: driverId, isMercenary: data.owner === 'courier' ? 0 : 1 });
						}
					} else {
						if (modalInfo && !modalInfo.isView) {
							modalInfo.findSpace(removeMaskEmall(value), { is_scanned: 1 }, { hasSurplus: true });
							return;
						}
					}
				});
			},
			loadInfoByDriver: flow(function* (driver: IDriver) {
				if (!driver.id) return;
				root.setLoading(true);

				try {
					const res = yield AcceptanceService.getDriverLists(driver.id);
					self.modalInfoByDriver = res.data || null;
					self.modalSheetByShipment = SheetByShipment.create({ driver });
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			closeModalInfoByDriver() {
				self.modalInfoByDriver = null;
			},
			confirm: flow(function* () {
				const driver = self.modalSheetByShipment?.driver;
				if (!driver?.id) return;

				root.setLoading(true);

				try {
					yield AcceptanceService.postConfirm(driver.id);
					root.acceptance.routeSheet.closeModalInfoByDriver();

					const res: { data: Sheet[] } = yield ShipmentService.getLists(0);
					const spaces = res.data.filter(({ status }) => status === 10);

					if (spaces.length) {
						self.modalSheetByShipment?.setSpaces(spaces);
					} else {
						self.modalSheetByShipment?.confirm({ isSkip: true });
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			closeSheetByShipment() {
				self.modalSheetByShipment = null;
				this.loadList();
			},
		};
	})
	.views((self) => ({
		get hasEntered() {
			return !!self.list?.find(({ status }) => status === 80);
		},
	}));

export interface Sheet extends SnapshotIn<typeof Sheet> {}
export default RouteSheet;
