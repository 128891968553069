import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import NoData from '@components/no-data';
import PostalInfoModal from '@components/postal-info-modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { IPostalTroubleItem } from '@store/acceptance/postal/postal-trouble';
import { Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const PostalTableTrouble: FC = () => {
	const {
		print,
		acceptance: { postal },
	} = useStore();
	const [currentId, setCurrentId] = useState<number | null>(null);

	const columns: ColumnsType<IPostalTroubleItem> = [
		{
			dataIndex: 'info_postal',
			render: (_, { number, status }) => (
				<div className={styles.status__space}>
					<Icon className={styles.mail_icon} imageName="Mail" />
					<div>
						<span>{number}</span>
						<span>{status ? `Статус ${status}` : 'Неопознанный груз'} </span>
					</div>
				</div>
			),
			width: 250,
		},
		{
			dataIndex: 'sender_receiver_full_name',
			render: (_, { receiver_full_name, sender_full_name }) => (
				<div className={styles.container__flex}>
					<span>{sender_full_name || ''}</span>
					<span>{receiver_full_name || ''} </span>
				</div>
			),
			width: 400,
		},
		{
			dataIndex: 'sizes',
			render: (_, { height, weight, length, width }) => (
				<div className={styles.container__flex}>
					{width || width || length ? <span>{`${width || ''} * ${height || ''} * ${length || '' + ' см'}`}</span> : ''}
					{weight ? <span>{weight}кг</span> : ''}
				</div>
			),
		},
		{
			dataIndex: 'button',
			render: (_, { id, status, is_reported, item_id }) => {
				if (status && !is_reported) {
					return (
						<Button onClick={() => postal.postalTrouble?.loadDataModalProblem(id)} className={styles.button}>
							Сообщить о проблеме
						</Button>
					);
				} else if (!status && status !== 0) {
					return (
						<div className={styles.actions}>
							<Tooltip placement="topRight" title="Распечатать временный  адресный ярлык">
								<IconButton
									onClick={() => print.setPrintPreview({ type: 'unidentified-item', id: item_id })}
									imageName="Sheet"
								/>
							</Tooltip>
						</div>
					);
				}
			},
		},
	];

	return postal.postalTrouble &&
		(postal.postalTrouble.dataTableItemTrouble || postal.postalTrouble.dataTableItemTrouble) ? (
		<>
			<Table
				pagination={false}
				columns={columns}
				dataSource={postal.postalTrouble.dataTableItemTrouble}
				onRow={({ item_id }) => ({ className: styles.row, onClick: () => item_id && setCurrentId(item_id) })}
				className={styles.table}
				onHeaderRow={() => ({ className: cn(styles.row, styles.row__head) })}
			/>
			<PostalInfoModal id={currentId} type="postal" onClose={() => setCurrentId(null)} isExtended />
		</>
	) : (
		<NoData className={styles.no__data} text="Нет почтовых отправлений" />
	);
};

export default observer(PostalTableTrouble);
