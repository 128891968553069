import PostalInfoModal from '@components/postal-info-modal';
import { useStore } from '@hooks/useStore';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import OpsChangeModal from './components/opsChangeModal';
import Search from './components/search';
import styles from './index.module.scss';

const ExtendedSearch: FC = () => {
	const { extendedSearch } = useStore();

	return (
		<Layout>
			<Header className={styles.header}>
				<h2 className={styles.title}>Расширенный поиск</h2>
				<p className={styles.description}>
					Введите номер посылки, наложенного платежа или номер заказа emall, чтобы получить доступ к информации о
					статусе доставки.
				</p>
			</Header>
			<Content className={styles.content}>
				<Search />
				<PostalInfoModal
					id={extendedSearch.selectedItem?.id || null}
					type={extendedSearch.selectedItem?.type as 'postal' | 'order'}
					onClose={() => extendedSearch.setSelectedItem(null)}
					isExtended
				/>
				<OpsChangeModal />
			</Content>
		</Layout>
	);
};

export default observer(ExtendedSearch);
