/**
 * Сравнивает два массива цветов и возвращает результат сравнения.
 * @param {Array<[boolean, boolean]>} colors - Массив из трех пар цветов (красный, жёлтый, зелёный).
 * @returns {-1 | 0 | 1} - Результат сравнения.
 */

export const sortRYG = (colors: [[boolean, boolean], [boolean, boolean], [boolean, boolean]]): -1 | 0 | 1 => {
	for (const [a, b] of colors) {
		if (a !== b) return a ? -1 : 1;
	}
	return 0;
};
