import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import NoData from '@components/no-data';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import {
	DocumentKeys,
	documentTypes,
} from '@pages/main/visits/components/visit/compoments/postal-table/components/documentTypes';
import { ConfigProvider } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Table: FC = () => {
	const {
		print,
		shipment: {
			post: {
				consolidation: { modalGoodsTable, setModalWrong },
			},
		},
	} = useStore();

	if (!modalGoodsTable) return <NoData text="Товаров нет" />;

	const { space, removeItem } = modalGoodsTable;

	return (
		<ConfigProvider
			theme={{
				token: {
					controlHeight: 32,
					borderRadius: 4,
					colorBgContainerDisabled: 'transparent',
					colorTextDisabled: colors.grey1,
				},
				components: { InputNumber: { hoverBorderColor: colors.black } },
			}}
		>
			{space && space.items.length > 0 ? (
				<table className={cn(styles.table, { [styles.table__trouble]: space.isTrouble })}>
					<thead className={styles.row}>
						<tr>
							<th className={styles.cell} style={{ width: 120 }}>
								<span>Тип</span>
							</th>
							<th className={styles.cell} style={{ width: 160 }}>
								<span>Трек-номер</span>
							</th>
							<th className={styles.cell} style={{ width: 120 }}>
								<span style={{ paddingLeft: 12 }}>Вес</span>
							</th>
							<th className={cn(styles.cell, styles.cell__error)}></th>
							<th className={styles.cell} style={{ width: 48 }}></th>
							<th className={styles.cell} style={{ width: 28 }}></th>
						</tr>
					</thead>
					<tbody>
						{space.sortItems.map(
							({
								id,
								number,
								weight,
								is_scanned,
								isSurplus,
								wrong_status_type: wrongStatus,
								item_id: itemId,
								currentStatus,
								routing_space_id: spaceId,
							}) => {
								const isScanned = modalGoodsTable.isStatic && !space.isTrouble ? !spaceId : is_scanned;

								return (
									<tr
										className={cn(styles.row, {
											[styles.row__new]: isSurplus,
											[styles.row__check]: isScanned,
											[styles.row__error]: wrongStatus && !space.isTrouble,
										})}
										key={id}
									>
										<td className={styles.cell} style={{ width: 120 }}>
											<span>Посылка</span>
										</td>
										<td className={styles.cell} style={{ width: 160 }}>
											<span>{number}</span>
										</td>
										<td className={styles.cell} style={{ width: 120 }}>
											<span>{weight} кг</span>
										</td>
										<td className={cn(styles.cell, styles.cell__error)}>{currentStatus}</td>
										<td className={cn(styles.cell, styles.cell__actions)} style={{ width: 48, textAlign: 'right' }}>
											{isScanned || isSurplus ? (
												<>
													<ContextMenu
														items={(['thermal', 'label'] as DocumentKeys[]).map((type) => ({
															text: documentTypes[type],
															onClick: (evt) => {
																evt.stopPropagation();
																print.setPrintPreview({ type, id: itemId });
															},
														}))}
													>
														<IconButton imageName="Sheet" />
													</ContextMenu>
													{/* <IconButton imageName="Exclamation" fontSize={20} /> */}
												</>
											) : (
												''
											)}
										</td>
										<td className={styles.cell} style={{ width: 28, textAlign: 'right' }}>
											{space.isTrouble || !isScanned ? null : (
												<IconButton
													imageName="Close"
													fontSize={20}
													onClick={() => {
														if (isScanned || wrongStatus) return setModalWrong(number);
														removeItem(id);
													}}
												/>
											)}
										</td>
									</tr>
								);
							}
						)}
					</tbody>
				</table>
			) : (
				<NoData text="Посылок нет" />
			)}
		</ConfigProvider>
	);
};

export default observer(Table);
