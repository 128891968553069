import NoData from '@components/no-data';
import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import ItemsModal from '@pages/main/acceptance/components/postal/components/cargo-spaces/components/items-modal';
import ModalConfirmUnidentified from '@pages/main/acceptance/components/postal/components/cargo-spaces/components/modal-confirm-unidentified';
import StatisticsProcess from '@pages/main/acceptance/components/postal/components/cargo-spaces/statistics-process';
import PostalTable from '@pages/main/acceptance/components/postal/components/cargo-spaces/table';
import ModalProblem from '@pages/main/acceptance/components/postal/components/problematic-parcels/components/modal-problem';
import PostalTableTrouble from '@pages/main/acceptance/components/postal/components/problematic-parcels/table';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import styles from './index.module.scss';

let timerId: any;

const PostalAcceptance: FC = () => {
	const {
		acceptance: { postal },
	} = useStore();

	const switcherRequests = (): void => {
		if (postal.tab === 'cargo-spaces') {
			postal.postalCargoSpaces?.loadPostalCargoSpaces();
		} else {
			postal.postalTrouble?.loadPostalTroubleSpaces();
		}
	};

	useScanner(postal.handleScanner);

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(switcherRequests, 100);
		return () => clearTimeout(timerId);
	}, [postal.tab]);

	return (
		<>
			{postal.tab === 'cargo-spaces' && (
				<div className={styles.cargo_spaces}>
					{postal.postalCargoSpaces?.postalCargoSpacesItems?.routing_spaces.length ? (
						<>
							<PostalTable />
							<StatisticsProcess />
						</>
					) : (
						<NoData className={styles.no__data} text="Нет почтовых отправлений" />
					)}
					<ModalConfirmUnidentified />
				</div>
			)}
			{postal.tab === 'problematic-parcels' && (
				<>
					<PostalTableTrouble />
				</>
			)}
			<ModalProblem />
			<ItemsModal />
		</>
	);
};

export default observer(PostalAcceptance);
