import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import styles from './index.module.scss';

const StatisticsProcess: FC = () => {
	const {
		acceptance: {
			postal: { postalCargoSpaces },
		},
	} = useStore();

	const percent = useMemo(() => {
		return (
			Math.round(
				((postalCargoSpaces?.postalCargoSpacesItems?.scanning_count_items || 0) * 100) /
					(postalCargoSpaces?.postalCargoSpacesItems?.count_items || 0)
			) || 0
		);
	}, [postalCargoSpaces?.postalCargoSpacesItems?.scanning_count_items]);

	return postalCargoSpaces ? (
		<div className={styles.statistics}>
			<span>Принято посылок</span>
			<span className={styles.percent}>{percent}%</span>
			<span
				style={{ background: `conic-gradient(#52C41A 0% ${percent}%, #FF4D4F ${percent}% 100%)` }}
				className={styles.counter}
			>
				<span>
					<span>
						<span className={styles.counter__current}>
							{postalCargoSpaces?.postalCargoSpacesItems?.scanning_count_items}
						</span>
						<span>/{postalCargoSpaces?.postalCargoSpacesItems?.count_items}</span>
					</span>
				</span>
			</span>
		</div>
	) : null;
};

export default observer(StatisticsProcess);
