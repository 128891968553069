import Button from '@components/buttons/button';
import Modal from '@components/modal';
import Tabs from '@components/tabs';
import { useStore } from '@hooks/useStore';
import InfoModalAttachCell from '@pages/main/acceptance/components/route-sheet/components/info-modal/components/info-modal-attach-cell';
import RoutingSpaces from '@pages/main/components/routing-spaces';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import Counters from './components/counters';
import Info from './components/info';
import styles from './index.module.scss';

const InfoModal: FC = () => {
	const {
		operator,
		acceptance: { modalInfo, closeModalInfo, routeSheet, confirmModalInfo, closeModalInfoLists },
	} = useStore();
	const [tab, setTab] = useState<'info' | 'spaces'>('spaces');

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	const close = (): void => {
		leave();
		setTab('spaces');
		closeModalInfo();
		if (routeSheet.isOpenModalContinueAcceptance) {
			routeSheet.setIsOpenModalContinueAcceptance(false);
		}
	};

	const accept = (): void => {
		if (routeSheet.isOpenModalContinueAcceptance) {
			closeModalInfo();
			routeSheet.setIsOpenModalContinueAcceptance(false);
			return;
		}
		confirmModalInfo();
	};

	const tabs = [
		{ key: 'spaces', label: 'Грузоместа' },
		{ key: 'info', label: 'Доп. инфо' },
	];

	useEffect(() => {
		if (!modalInfo) return close();

		if (modalInfo && !modalInfo?.isView) {
			window.Echo &&
				operator?.id &&
				listenChannel(
					({ number }) => modalInfo.findSpace(number, { is_scanned: 1 }, { hasSurplus: true }),
					'.routing.number',
					operator?.id
				);
		}

		return leave;
	}, [modalInfo]);

	return (
		<>
			<Modal
				title={routeSheet.isModalInfoCargoSpace ? 'Приемка грузомест' : `Маршрутный лист ${modalInfo?.id || ''}`}
				footer={
					<div className={styles.footer}>
						<Button onClick={close}>Закрыть</Button>
						{routeSheet.isModalInfoCargoSpace ? (
							<Button type="primary" onClick={accept}>
								Завершить приемку
							</Button>
						) : (
							!modalInfo?.isView && (
								<Button type="primary" onClick={close} disabled={modalInfo?.submitDisabled}>
									Отгрузить
								</Button>
							)
						)}
					</div>
				}
				open={!!modalInfo || routeSheet.isOpenModalContinueAcceptance}
				width={880}
				onCancel={close}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
			>
				{routeSheet.isModalInfoView ? (
					<Tabs
						className={styles.tabs}
						items={tabs}
						activeKey={tab}
						onChange={(key) => setTab(key as typeof tab)}
						moreIcon={null}
					/>
				) : !routeSheet.isOpenModalContinueAcceptance ? (
					<Counters />
				) : null}
				{tab === 'spaces' ? (
					<RoutingSpaces spaces={modalInfo?.routing_spaces || []} isEdit={!modalInfo?.isView} />
				) : (
					<Info />
				)}
			</Modal>
			<Modal
				classNameModal={styles.confirm}
				className={styles.confirm__body}
				title={null}
				okText="Досканировать"
				cancelText="Принять с расхождением"
				open={!!modalInfo?.confirmOpened}
				onCancel={closeModalInfoLists}
				onOk={() => modalInfo && modalInfo.setConfirmOpened(false)}
				hasLines={false}
				centered
				width={430}
				closable={false}
			>
				<h3 className={styles.confirm__title}>Отсканированы не все места из списка</h3>
				<p className={styles.confirm__text}>Досканируйте или примите с расхождением</p>
			</Modal>
			<InfoModalAttachCell />
		</>
	);
};

export default observer(InfoModal);
