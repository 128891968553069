import { api } from '@http/index';
import { ITask } from '@store/tasks';
import { AxiosResponse } from 'axios';

const getTasks = (): Promise<AxiosResponse<ITask>> => api.get('/task');

const processPostal = (id: number): Promise<AxiosResponse<{ message: string }>> =>
	api.post(`/task/postal/${id}/processing`);

export const TaskService = { getTasks, processPostal };
