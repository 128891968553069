import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import MailTasksTable from './components/table';

const MailTasks: FC = () => {
	const { tasks } = useStore();

	useEffect(() => {
		if (tasks?.search?.length) tasks.setSearch('');
	}, []);

	return <MailTasksTable />;
};

export default observer(MailTasks);
