import { useStore } from '@hooks/useStore';
import PrintMenu from '@pages/main/acceptance/components/print-menu';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Counters: FC<{ isByDriver?: boolean }> = ({ isByDriver = false }) => {
	const {
		acceptance: {
			modalInfo,
			routeSheet: { modalInfoByDriver },
		},
	} = useStore();

	if (isByDriver ? !modalInfoByDriver : !modalInfo) return null;

	return (
		<div className={styles.wrapper}>
			{!isByDriver && <PrintMenu />}
			<div className={styles.counters}>
				<ul className={styles.list}>
					<li className={styles.item}>
						<span className={styles.item__name}>Мешок</span>
						{isByDriver ? (
							<span className={styles.counter__current}>{modalInfoByDriver?.counts.bags}</span>
						) : (
							<span className={styles.counter}>
								<span className={styles.counter__current}>{modalInfo?.counts.bags_scanned}</span>
								<span>/{modalInfo?.counts.bags}</span>
							</span>
						)}
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Посылка</span>
						{isByDriver ? (
							<span className={styles.counter__current}>{modalInfoByDriver?.counts.postals}</span>
						) : (
							<span className={styles.counter}>
								<span className={styles.counter__current}>{modalInfo?.counts.postals_scanned}</span>
								<span>/{modalInfo?.counts.postals}</span>
							</span>
						)}
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Ящик</span>
						{isByDriver ? (
							<span className={styles.counter__current}>{modalInfoByDriver?.counts.boxes}</span>
						) : (
							<span className={styles.counter}>
								<span className={styles.counter__current}>{modalInfo?.counts.boxes_scanned}</span>
								<span>/{modalInfo?.counts.boxes}</span>
							</span>
						)}
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Товар</span>
						{isByDriver ? (
							<span className={styles.counter__current}>{modalInfoByDriver?.counts.goods}</span>
						) : (
							<span className={styles.counter}>
								<span className={styles.counter__current}>{modalInfo?.counts.goods_scanned}</span>
								<span>/{modalInfo?.counts.goods}</span>
							</span>
						)}
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Денежный мешок</span>
						{isByDriver ? (
							<span className={styles.counter__current}>{modalInfoByDriver?.counts.money_bags}</span>
						) : (
							<span className={styles.counter}>
								<span className={styles.counter__current}>{modalInfo?.counts.money_bags_scanned}</span>
								<span>/{modalInfo?.counts.money_bags}</span>
							</span>
						)}
					</li>
				</ul>
				{isByDriver ? (
					<span className={styles.counter__total}>{modalInfoByDriver?.counts.total}</span>
				) : (
					<span className={cn(styles.counter, styles.counter__total)}>
						<span className={styles.counter__current}>{modalInfo?.counts.total_scanned}</span>
						{modalInfo?.surplusCount ? (
							<span className={styles.counter__surplus}>+{modalInfo.surplusCount}</span>
						) : null}
						{modalInfo?.errorCount ? <span className={styles.counter__error}>+{modalInfo.errorCount}</span> : null}
						<span>/{modalInfo?.counts.total}</span>
					</span>
				)}
			</div>
		</div>
	);
};

export default observer(Counters);
