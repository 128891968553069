import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import NoData from '@components/no-data';
import PostalInfoModal from '@components/postal-info-modal';
import { useStore } from '@hooks/useStore';
import {
	DocumentKeys,
	documentTypes,
} from '@pages/main/visits/components/visit/compoments/postal-table/components/documentTypes';
import { IPostal } from '@store/shipment/post/postal';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const Parcels: FC = () => {
	const [currentId, setCurrentId] = useState<number | null>(null);
	const [visible, setVisible] = useState(false);
	const {
		print,
		shipment: {
			post: { parcels },
		},
	} = useStore();

	const columns: ColumnsType<IPostal> = [
		{
			title: 'Дата приемки на ОПС',
			dataIndex: 'date',
			render: (_, { arrival_at: date }) => <span>{date}</span>,
			width: 176,
		},
		{
			title: 'Трек-номер',
			dataIndex: 'code',
			render: (_, { number }) => <span>{number}</span>,
			width: 140,
		},
		{
			title: 'Вес',
			dataIndex: 'weight',
			render: (_, { weight }) => <span>{weight} кг</span>,
			width: 140,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			render: (_, { reason_name: name }) => <span className={styles.status}>{name}</span>,
			width: 200,
		},
		{
			dataIndex: 'actions',
			render: (_, { item_id: id }) => (
				<span className={cn(styles.actions, { [styles.actions__visible]: visible })}>
					<ContextMenu
						onOpenChange={(visible) => setVisible(visible)}
						items={(['thermal', 'label'] as DocumentKeys[]).map((type) => ({
							text: documentTypes[type],
							onClick: (evt) => {
								evt.stopPropagation();
								print.setPrintPreview({ type, id });
							},
						}))}
					>
						<IconButton imageName="Sheet" />
					</ContextMenu>
					{/* <IconButton imageName="Exclamation" fontSize={20} /> */}
				</span>
			),
		},
	];

	return parcels.items && parcels.items.length > 0 ? (
		<>
			<Table
				pagination={false}
				columns={columns}
				dataSource={parcels.items}
				onRow={({ item_id: id }) => ({
					className: styles.row,
					onClick: () => setCurrentId(id),
				})}
				className={styles.table}
				locale={{ emptyText: 'Нет данных' }}
				onHeaderRow={() => ({ className: cn(styles.row, styles.row__head) })}
			/>
			<PostalInfoModal id={currentId} type="postal" onClose={() => setCurrentId(null)} isExtended />
		</>
	) : (
		<NoData text="Посылок нет" />
	);
};

export default observer(Parcels);
