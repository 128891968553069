import OperatorService from '@services/operator';
import ShiftService, { IBreakData } from '@services/shift';
import { TaskService } from '@services/task';
import UserService from '@services/user';
import { applySnapshot, cast, flow, Instance, SnapshotIn, types } from 'mobx-state-tree';
import React, { FC, PropsWithChildren } from 'react';

import { AcceptancetModel } from './acceptance';
import { CompensationsModel } from './compensations';
import ExtendedSearch from './extended-search';
import IndentificationModel from './identification';
import { KassaModel } from './kassa';
import Message from './message';
import Notice from './notice';
import Operator from './operator';
import Print from './print';
import Scanner from './scanner';
import Shift from './shift';
import { ShipmentModel } from './shipment';
import { ITask, TasksModel } from './tasks';
import UserModel, { User } from './user';
import Visits from './visits';

const { model, maybeNull, number, boolean, optional } = types;

export const RootStore = model({
	operator: maybeNull(Operator),
	visits: Visits,
	indentification: IndentificationModel,
	tasks: TasksModel,
	compensations: CompensationsModel,
	kassa: KassaModel,
	user: maybeNull(UserModel),
	shift: maybeNull(Shift),
	postOffice: maybeNull(number),
	dataLoaded: false,
	print: Print,
	loadModal: optional(boolean, false),
	loading: false,
	loadingForce: false,
	isPaymentError: false,
	notice: Notice,
	message: maybeNull(Message),
	breakConfirm: false,
	shipment: ShipmentModel,
	acceptance: AcceptancetModel,
	extendedSearch: ExtendedSearch,
	scanner: Scanner,
	driverModal: false,
}).actions((self) => ({
	setDriverModal(value: boolean) {
		self.driverModal = value;
	},
	setUser(user: User) {
		self.user = user;
	},
	logout: flow(function* () {
		yield UserService.logout();
		self.user = null;
		localStorage.removeItem('user');
		localStorage.removeItem('token');
	}),
	openShift: flow(function* () {
		if (self.postOffice) {
			try {
				const shift = yield ShiftService.openShift(self.postOffice);
				self.shift = shift.data;
			} catch (err: any) {
				self.notice.setNotice({ message: err?.error || err?.message || '' });
			}
		}
	}),
	checkShift: flow(function* () {
		try {
			const shift = yield ShiftService.getShift();
			self.shift = shift.data;
			self.dataLoaded = true;
		} catch (err: any) {
			self.dataLoaded = true;
			err?.message !== 'canceled' && console.log('checkShift: ', err);
		}
	}),
	closeShift: flow(function* () {
		if (self.shift) {
			if (rootStore.operator?.is_pos_enabled) {
				rootStore.kassa.setZReport(true);
				rootStore.kassa.setPrintStatus('process');
			}

			try {
				rootStore.print.printReports();

				yield ShiftService.closeShift(self.shift.shift_id);

				self.shift = null;
				applySnapshot(self.visits.visitMap, { 1: {} });
				self.operator?.setPos(false);
			} catch (err: any) {
				rootStore.kassa.setZReport(false);
				rootStore.kassa.setPrintStatus(null);
				self.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				rootStore.setLoadingForce(false);
				rootStore.setLoading(false);
			}
		}
	}),
	openBreak: flow(function* (data: IBreakData) {
		if (self.shift && self.shift.status === 0) {
			try {
				const shift = yield ShiftService.openBreak(self.shift.shift_id, data);
				self.shift = shift.data;
			} catch (err: any) {
				self.notice.setNotice({ message: err?.error || err?.message || '' });
			}
		}
	}),
	closeBreak: flow(function* () {
		if (self.shift) {
			try {
				const shift = yield ShiftService.closeBreak(self.shift.shift_id, self.shift.break?.id as number);
				self.shift = shift.data;
			} catch (err: any) {
				self.notice.setNotice({ message: err?.error || err?.message || '' });
			}
		}
	}),
	setPostOffice(officeId: number) {
		self.postOffice = officeId;
		localStorage.setItem('postOffice', String(officeId));
	},
	loadTask: flow(function* (noLoading?: boolean) {
		try {
			!noLoading && (self.loading = true);

			const { data }: { data: ITask } = yield TaskService.getTasks();

			self.tasks = cast(data);
			!noLoading && (self.loading = false);
		} catch (err) {
			console.log('tasks', err);
			!noLoading && (self.loading = false);
		}
	}),
	setLoading(value: boolean) {
		self.loading = value;
	},
	setLoadingForce(value: boolean) {
		self.loadingForce = value;
	},
	setPaymentError(value: boolean) {
		self.isPaymentError = value;
	},
	setOperator: flow(function* () {
		try {
			const { data } = yield OperatorService.getOperator();
			self.operator = data;
			self.postOffice = data.store.id;
			return data;
		} catch (err) {
			console.log('error operator/info: ', err);
		}
	}),
	setMessage(data: { title: string; description: string; html?: string } | null) {
		self.message = data ? { ...data, html: data.html || '' } : null;
	},
	cleanShift() {
		self.shift = null;
		applySnapshot(self.visits.visitMap, { 1: {} });
		self.operator?.setPos(false);
	},
	showBreakConfirm(value: boolean) {
		self.breakConfirm = value;
	},
}));

const restorePostoffice = (): number | null => {
	const officeId = localStorage.getItem('postOffice');
	if (officeId && !Number.isNaN(officeId)) {
		return Number(officeId);
	} else return null;
};

const restorePrinters = (): SelectedPrinters => {
	const printers = localStorage.getItem('printers');
	const errors = { errorOthers: false, errorThermal: false };
	if (printers) return { printers: JSON.parse(printers), tmpPrinters: {}, ...errors };
	else return { printers: {}, tmpPrinters: {}, ...errors };
};

const rootStore = RootStore.create({
	visits: { visitMap: { 1: {} } },
	postOffice: restorePostoffice(),
	print: restorePrinters(),
	compensations: { list: null },
	kassa: { list: null },
	indentification: { user: null },
	notice: {},
	shipment: {
		headerInfo: {
			count_routing_lists: 0,
			emall_consolidate_count: 0,
			emall_count: 0,
			postal_consolidate_count: 0,
			postal_count: 0,
			has_postal: 0,
			has_emall: 0,
			has_money: 0,
		},
		routeSheet: {},
		emall: { consolidation: { modalCreateSpace: {}, modalMoving: {} }, goods: {} },
		post: {
			consolidation: { spaces: { dynamic_spaces: null, static_spaces: null } },
			parcels: {},
		},
		modalInfo: null,
	},
	acceptance: {
		headerInfo: {
			count_routing_lists: 0,
			has_postal: 0,
			has_emall: 0,
			has_money: 0,
			emall_bags: 0,
			emall_products: 0,
			postal_spaces: 0,
			postal_trouble_items: 0,
		},
		routeSheet: {},
		emall: {},
		postal: { postalCargoSpaces: {}, postalTrouble: {} },
		modalInfo: null,
	},
	extendedSearch: {},
	scanner: {},
	tasks: { postal_quantity: 0, order_quantity: 0, postal_items: [], orders: [] },
});

export const StoreContext = React.createContext(rootStore);

const StoreProvider: FC<PropsWithChildren> = ({ children }) => (
	<StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
);

export default StoreProvider;

export type SelectedPrinters = SnapshotIn<typeof RootStore>['print'];
export type RootStoreType = Instance<typeof RootStore>;
