import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { IItem } from '@store/acceptance/emall';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const ItemsTable: FC = () => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const {
		acceptance: {
			emall: { modalItems },
		},
	} = useStore();

	if (!modalItems) return <NoData text="Пакетов нет" />;

	const { hasItems, sortItems } = modalItems;

	const columns: ColumnsType<IItem> = [
		{
			title: <span>Номер пакета</span>,
			dataIndex: 'code',
			className: styles.cell,
			width: 150,
			render: (_, { name }) => <span>{name}</span>,
		},
		{
			title: <span>Номер заказа</span>,
			dataIndex: 'name',
			className: styles.cell,
			render: (_, { order_market_id: id }) => <span>{id}</span>,
		},
		// {
		// 	dataIndex: 'action',
		// 	className: styles.cell,
		// 	width: 28,
		// 	render: (_, item) => (
		// 		<span style={{ width: 136 }}>
		// 			{item.is_scanned || item.isNewError || item.isSurplus ? (
		// 				<IconButton imageName="Close" fontSize={20} onClick={() => modalItems.deleteItem(item)} />
		// 			) : (
		// 				''
		// 			)}
		// 		</span>
		// 	),
		// },
	];

	return hasItems ? (
		<Table
			pagination={false}
			columns={columns}
			dataSource={sortItems?.map((item) => ({ ...item, key: item.id }))}
			onRow={({ is_scanned: isScan, isNewError, isSurplus }) => ({
				className: cn(styles.row, {
					[styles.row__check]: isScan,
					[styles.row__new]: isSurplus,
					[styles.row__error]: isNewError,
				}),
			})}
			onHeaderRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
			expandable={{
				expandedRowClassName: () => cn(styles.row, styles.row__expand, styles.row__error),
				showExpandColumn: false,
				expandedRowRender: ({ message }) => <span className={styles.error}>{message}</span>,
				rowExpandable: ({ isNewError, key }) => {
					if (isNewError && key && !expandedRowKeys.includes(key)) setExpandedRowKeys((value) => [...value, key]);
					return !!isNewError;
				},
				expandedRowKeys,
			}}
		/>
	) : (
		<NoData text="Пакетов нет" />
	);
};

export default observer(ItemsTable);
