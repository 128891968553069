// import NoData from '@components/no-data';
import IconButton from '@components/buttons/icon_btn';
import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { IOrder } from '@store/acceptance/emall';
import { Tag, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const EmallTable: FC = () => {
	const {
		print,
		acceptance: { emall },
	} = useStore();

	const isProduct = emall.tab === 'product';

	const columns: ColumnsType<IOrder> = [
		{
			title: 'Номер заказа',
			dataIndex: 'number',
			render: (_, { order_market_id: id, order_status: status }) => (
				<>
					{isProduct && (
						<Icon
							imageName={status === 10 ? 'ShoppingBag' : 'ShoppingBagYellow'}
							fontSize={20}
							color="black"
							fill="black"
						/>
					)}
					<span className={cn({ [styles.id]: isProduct })}>{id}</span>
				</>
			),
			width: isProduct ? 168 : 116,
		},
		{
			title: 'ФИО получателя',
			dataIndex: 'name',
			render: (_, { receiver_fio: name }) => <span>{name}</span>,
			width: 332,
		},
		{
			title: isProduct ? 'Общее количество товаров' : 'Пакеты',
			render: (_, { bags, product_count: count }) =>
				isProduct ? (
					<span>{count} шт.</span>
				) : (
					<div>
						{bags
							? bags.map(({ name, is_scanned: isScan }) => (
									<Tooltip key={name} title={isScan ? 'Пакет принят' : 'Пакет не принят'}>
										<Tag className={cn(styles.tag, { [styles.tag__scan]: isScan })}>{name}</Tag>
									</Tooltip>
							  ))
							: null}
					</div>
				),
		},
		{
			title: '',
			width: 72,
			render: (_, order) =>
				isProduct ? null : (
					<div className={styles.actions}>
						<Tooltip title="Товарный чек">
							<IconButton
								imageName="Sheet"
								onClick={() =>
									print.setPrintPreview({ type: 'sales-receipt', number: String(order.order_market_id), id: order.id })
								}
							/>
						</Tooltip>
						{/* <Tooltip title="Инцидент">
							<IconButton imageName="Exclamation" onClick={() => emall.hideOrder(order)} />
						</Tooltip> */}
					</div>
				),
		},
	];

	if (!emall.orders || !emall.orders.length) return <NoData text="Нет заказов Emall" />;

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={emall.orders ? [...emall.orders] : []}
			onRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
			onHeaderRow={() => ({ className: cn(styles.row, styles.row__head) })}
			rowKey={(item) => item.id}
		/>
	);
};

export default observer(EmallTable);
