/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import ProductService from '@services/product';
import { IProductScan, ShipmentService } from '@services/shipment';
import { cast, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';
import { resetFocusElement } from 'utils/resetFocusElement';
import { voiceMessage } from 'utils/voiceMessage';

import { soundNotification } from '../../../utils/soundNotification';
import { RootStore } from '../..';
import Product, { IProduct } from './product';

const { model, maybeNull, union, literal, array, string, number, optional } = types;

const Space = model('space', {
	id: number,
	type: union(literal(3), literal(4)),
	type_name: union(literal('Ящик'), literal('Товар')),
	is_scanned: union(literal(0), literal(1)),
	number: string,
	routing_items_count: maybeNull(number),
	status: number,
});

const Goods = model('goods', {
	id: number,
	name: string,
	market_id: number,
	count: optional(number, 1),
});

const GoodsSpace = model('modal-goods-space', {
	count: number,
	scanned_count: number,
	items: array(Product),
	id: number,
}).views((self) => ({
	get surplusCount() {
		return self.items.filter(({ isSurplus }) => isSurplus).length;
	},
	get hasScanned() {
		return !!self.items.filter(({ is_scanned }) => is_scanned).length;
	},
	get hasSurplusScanned() {
		return self.items.some(({ reason, is_scanned }) => is_scanned && reason === 'Излишек');
	},
}));

const ModalGoodsTable = model('modal-goods-table', {
	show: false,
	showConfirm: false,
	deleteId: maybeNull(number),
	barcode: maybeNull(number),
	sign: '',
	space: maybeNull(GoodsSpace),
	isUpdateTable: false,
}).actions((self) => {
	return {
		setShow(value: boolean) {
			self.show = value;
		},
		setShowConfirm(value: boolean) {
			self.showConfirm = value;
		},
		setDeleteId(value: number | null) {
			self.deleteId = value;
			resetFocusElement();
		},
		scanBarcode: flow(function* (barcode: string, count?: number) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет товаров' });
				throw 'Нет товаров';
			}

			root.setLoading(true);
			self.isUpdateTable = true;

			try {
				const { data }: { data: IProductScan[] } = yield ShipmentService.postEmallItemScan({
					barcode,
					count: count ? count - 1 : 1,
					routing_space_id: self.space.id || 0,
				});

				if (data.length === 1) {
					const { scanning_message: message, scanning_status: status, ...item } = data[0];
					status === 2 ? voiceMessage({ message, isSave: true }) : soundNotification(true);

					if (status === 2) {
						self.space.items.unshift({ ...item, isSurplus: true });
					} else {
						const arr = self.space?.items?.filter((el) => el.id !== item.id) || [];
						self.space.items = cast([item, ...arr]);
					}

					root.setLoading(false);
				}

				if (data.length > 1) {
					voiceMessage({ message: 'Успешно', isSave: true });
					root.shipment.emall.consolidation.loadSpace(self.space.id);
				}
			} catch (err: any) {
				root.setLoading(false);
				root.notice.setNotice({ message: err?.error || err?.message || '' });
				throw err?.error || err?.message || 'Ошибка';
			} finally {
				self.isUpdateTable = false;
			}
		}),
		setBarcode(value: number | null) {
			self.barcode = value;
		},
		deleteItem: flow(function* (barcode: string) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id || !self.deleteId) {
				root.notice.setNotice({ message: 'Нет товаров' });
				return;
			}

			root.setLoading(true);

			try {
				yield ShipmentService.putItems(self.deleteId, { is_scanned: 0, barcode });
				root.shipment.emall.consolidation.loadSpace(self.space.id);
				self.deleteId = null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setSign(value: string) {
			self.sign = value;
		},
		close() {
			const root = getRoot<typeof RootStore>(self);
			root.shipment.emall.consolidation.loadSpace(null);
			root.shipment.emall.consolidation.loadSpaces();
		},
		closeConfirm() {
			self.sign = '';
			self.showConfirm = false;
		},
		submit: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			const id = self.space?.id;

			if (!id) {
				root.notice.setNotice({ message: 'Нет id' });
				return;
			}

			root.setLoading(true);

			try {
				yield ShipmentService.putSpaces(id, { number: self.sign });

				root.shipment.emall.consolidation.modalGoodsTable?.closeConfirm();
				root.shipment.emall.consolidation.modalGoodsTable?.close();

				self.space?.hasSurplusScanned && root.print.printTask({ type: 'routing-surplus', id });
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setIsUpdateTable(value: boolean) {
			self.isUpdateTable = value;
		},
	};
});

const ModalCreateSpace = model('create-space', {
	show: false,
	barcode: maybeNull(number),
	goods: maybeNull(Goods),
	sign: '',
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		setShow(value: boolean) {
			self.show = value;
		},
		setBarcode(value: number | null) {
			self.barcode = value;
		},
		setGoods(value: IGoods | null) {
			self.goods = cast(value);
		},
		setGoodsCount(value: number | null) {
			if (value && self.goods) {
				self.goods.count = value;
			}
		},
		setSign(value: string) {
			self.sign = value;
		},
		addGoods: flow(function* (barcode: number) {
			root.setLoading(true);

			try {
				const res = yield ProductService.getProduct(barcode);
				self.goods = Goods.create(res.data);
				self.barcode = barcode;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
	};
});

const ModalMoving = model('modal-moving', {
	show: false,
	inputFrom: '',
	inputTo: '',
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		setShow(value: boolean) {
			self.show = value;
		},
		setInputFrom(value: string) {
			self.inputFrom = value;
		},
		setInputTo(value: string) {
			self.inputTo = value;
		},
		setInput(value: string) {
			if (self.inputFrom) {
				if (!self.inputTo) {
					self.inputTo = value;
					this.submit();
				}
			} else {
				self.inputFrom = value;
			}
		},
		closeModal() {
			self.show = false;
			self.inputFrom = '';
			self.inputTo = '';
		},
		submit: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getSpace(self.inputFrom);
				yield ShipmentService.putSpaces(res.data.id, { number: self.inputTo });
				root.shipment.emall.consolidation.loadSpaces();
				root.shipment.emall.consolidation.modalMoving.closeModal();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
	};
});

const ConsolidationModel = model('consolidation-model', {
	spaces: array(Space),
	modalGoodsTable: maybeNull(ModalGoodsTable),
	modalCreateSpace: ModalCreateSpace,
	modalBarcodeGoods: false,
	modalMoving: ModalMoving,
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);

		return {
			loadSpaces: flow(function* () {
				root.setLoading(true);
				try {
					const res = yield ShipmentService.getEmallSpaces();
					self.spaces = res.data || null;
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			removeSpace: flow(function* (id: number) {
				root.setLoading(true);
				try {
					yield ShipmentService.deleteSpace(id);
					root.shipment.emall.consolidation.loadSpaces();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			loadSpace: flow(function* (id: number | null, addItems?: IProduct[] | null) {
				if (!id) {
					self.modalGoodsTable?.setShow(false);
					return;
				}

				root.setLoading(true);

				try {
					const res = yield ShipmentService.getEmallSpaceId(id);
					const space = res.data;

					if (addItems) {
						addItems.forEach((item) => {
							const foundIndex = space.items.findIndex((o: IProduct) => o.id === item.id);

							if (foundIndex !== -1) {
								space.items[foundIndex].isSurplus = true;
								space.items.sort((a: IProduct, b: IProduct) => {
									const foundId = space.items[foundIndex].id;
									return +(b.id === foundId || b.isSurplus || false) - +(a.id === foundId || a.isSurplus || false);
								});
							} else {
								space.items.unshift(item);
							}
						});
					}

					self.modalGoodsTable = ModalGoodsTable.create({ space, show: true });
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			showModalBarcodeGoods(value: boolean) {
				self.modalBarcodeGoods = value;
			},
			addSpace: flow(function* (name: 'Ящик' | 'КГТ') {
				root.setLoading(true);

				const { sign, barcode, goods } = self.modalCreateSpace;

				try {
					yield ShipmentService.postEmallSpaces(
						name === 'Ящик'
							? { type: 3 }
							: { type: 4, number: sign, barcode: barcode ? String(barcode) : null, count: goods?.count || 1 }
					);

					root.shipment.emall.consolidation.loadSpaces();
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
		};
	})
	.views((self) => ({
		getScanned() {
			return self?.modalGoodsTable?.space?.items?.filter((el) => !!el.is_scanned && !el.isSurplus)?.length || 0;
		},
		getSpaceLength() {
			return self.modalGoodsTable?.space?.items?.length || 0;
		},
	}));

export default ConsolidationModel;

export interface IGoods extends SnapshotIn<typeof Goods> {}
export interface IGoodsSpace extends SnapshotIn<typeof GoodsSpace> {}
