/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import Postal from '@store/acceptance/postal';
import RoutingInfo, { ISpaceRouting, PutSpaceDataType } from '@store/routing-info';
import { cast, flow, getRoot, types } from 'mobx-state-tree';

import { voiceMessage } from '../../utils/voiceMessage';
import { RootStore } from '..';
import Emall from './emall';
import Header from './header';
import RouteSheet from './route-sheet';

const { model, maybeNull, boolean, number, string } = types;

const ItemCargoSpace = model('item-cargo-space', {
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	item: model('item-space', {
		id: maybeNull(number),
		number: string,
		message: maybeNull(string),
		status: number,
	}),
});

export const AcceptancetModel = model('Acceptance', {
	headerInfo: Header,
	routeSheet: RouteSheet,
	emall: Emall,
	postal: Postal,
	modalInfo: maybeNull(RoutingInfo),
	driverId: maybeNull(number),
	modalInfoAttachCell: maybeNull(string),
	infoScannedItem: maybeNull(ItemCargoSpace),
	isNewCell: maybeNull(boolean),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		setSpace: flow(function* (id: number, data: PutSpaceDataType) {
			root.setLoading(true);
			try {
				if (data?.number) {
					root?.acceptance?.setPostalItemScan(data.number);
				} else {
					yield AcceptanceService.putSpaces(id, data);
				}

				if (self.modalInfo?.id) {
					root.acceptance.loadRoutingInfo(self.modalInfo.id);
					return;
				}

				root.acceptance.routeSheet.isModalInfoCargoSpace && root.acceptance.loadSpaces();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		addSpace: flow(function* (number: string) {
			if (!self.modalInfo?.routing_spaces) {
				root.notice.setNotice({ message: 'Нет пакетов' });
				return;
			}

			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getSpace(number);
				self.modalInfo.routing_spaces.unshift({ ...res.data, [res.data.is_surplus ? 'isError' : 'isSurplus']: true });
			} catch (err: any) {
				root.notice.setNotice({ message: err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setPostalItemScan: flow(function* (number: string) {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.postPostalScanNumber(number);
				self.infoScannedItem = res.data;
				self.modalInfoAttachCell = self.infoScannedItem?.item.message || '';

				voiceMessage({ message: self.infoScannedItem?.store_place_item_text || '' });
			} catch (err: any) {
				root.setMessage({ title: err?.message || '', description: '' });
			} finally {
				root.setLoading(false);
			}
		}),
		scanContinueAcceptance: flow(function* (number: string) {
			root.setLoading(true);
			if (
				self.modalInfo &&
				Array.isArray(self.modalInfo.routing_spaces) &&
				self.modalInfo.routing_spaces?.some((el) => el.number === number)
			) {
				return;
			}
			try {
				const res = yield AcceptanceService.postSpacesNumberScan(number);

				const item: ISpaceRouting = {
					number: res.data.routing_space.number,
					is_scanned: res.data.routing_space?.is_scanned ?? 0,
					id: res.data.routing_space?.id ?? 0,
					type: res.data.routing_space?.type ?? 0,
					routing_items_count: res.data.routing_space?.routing_items_count ?? 0,
					status: res.data.status,
					isError: Boolean(res.data.status === 2),
					isSurplus: false,
				};

				if (!self.modalInfo) {
					self.modalInfo = cast({
						id: null,
						status: null,
						created_at: null,
						transferred_at: null,
						shipped_at: null,
						arrival_at: null,
						accepted_at: null,
						driver_fio: null,
						driver_phone: null,
						car_plate: null,
						is_mercenary: null,
						counts: {
							bags: 0,
							bags_scanned: 0,
							postals: 0,
							postals_scanned: 0,
							boxes: 0,
							boxes_scanned: 0,
							goods: 0,
							goods_scanned: 0,
							money_bags: 0,
							money_bags_scanned: 0,
							total_scanned: 0,
							total: 0,
						},
						routing_spaces: [],
						routing_list_id: [],
						confirmOpened: false,
					});
				}

				if (self.modalInfo) {
					self.modalInfo.routing_spaces.push(item);
				}

				voiceMessage({ message: res.data.message || '' });
			} catch (err: any) {
				root.setMessage({ title: err?.message || '', description: '' });
			} finally {
				root.setLoading(false);
			}
		}),
		scanCell: flow(function* (number: string) {
			root.setLoading(true);
			try {
				if (number === 'NewCell') {
					self.isNewCell = true;
					return;
				}

				if (
					self.infoScannedItem?.store_place_item_id &&
					self.infoScannedItem?.store_place_item_id.toString() !== number.replace(/^0+/, '') &&
					!self.isNewCell
				) {
					voiceMessage({ message: 'Неверная ячейка' });
					return;
				}

				yield AcceptanceService.postStorePlaceItemsScan({
					store_place_item_id_to_block: self.isNewCell ? Number(self.infoScannedItem?.store_place_item_id) : null,
					store_place_item_id_to_choose: Number(number),
					item_id: Number(self.infoScannedItem?.item.id),
					routing_space_id: Number(self.modalInfo?.routing_list_id && self.modalInfo?.routing_list_id[0]),
				});
				self.isNewCell = false;
				self.modalInfoAttachCell = null;
				self.infoScannedItem = null;
				root.acceptance.loadSpaces();
				voiceMessage({ message: 'Успешно' });
			} catch (err: any) {
				root.setMessage({ title: err?.message || '', description: '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadHeaderInfo: flow(function* () {
			try {
				const res = yield AcceptanceService.getInfo();
				self.headerInfo = res.data;
			} catch (err: any) {
				console.log('loadHeaderInfo: ', err?.error || err?.message || '');
			}
		}),

		closeModalInfoAttachCell() {
			self.modalInfoAttachCell = null;
		},
		loadRoutingInfo: flow(function* (id: number) {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getList(id);
				self.modalInfo = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadSpaces: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getSpaces();
				const addItems = self.modalInfo?.routing_spaces.filter(({ isSurplus, isError }) => isSurplus || isError) || [];
				self.modalInfo = res.data ? { ...res.data, routing_spaces: [...addItems, ...res.data.routing_spaces] } : null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalInfo() {
			self.modalInfo = null;
			self.routeSheet.setIsModalInfoView(false);
			self.routeSheet.setIsModalInfoCargoSpace(false);
		},
		closeModalInfoLists: flow(function* () {
			if (!self.modalInfo?.routing_list_id) return;
			root.setLoading(true);

			try {
				yield AcceptanceService.postListsClose({ routing_list_id: self.modalInfo.routing_list_id });
				root.acceptance.routeSheet.loadList();
				root.acceptance.closeModalInfo();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		confirmModalInfo() {
			if (!self.modalInfo?.routing_spaces) return;

			if (self.modalInfo.routing_spaces.find(({ is_scanned }) => !is_scanned)) {
				self.modalInfo.setConfirmOpened(true);
			} else {
				root.acceptance.closeModalInfoLists();
			}
		},
	};
});
