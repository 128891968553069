import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { IStateConfirmModalCollect } from '@pages/main/visits/components/visit';
import { FC } from 'react';

import styles from './index.module.scss';

interface IProps {
	setStateConfirmModal: (state: IStateConfirmModalCollect) => void;
	stateConfirmModal: IStateConfirmModalCollect;
}

const ModalConfirmTypeCollect: FC<IProps> = ({ stateConfirmModal, setStateConfirmModal }) => {
	const { tasks } = useStore();

	return (
		<Modal
			width={416}
			onCancel={() => setStateConfirmModal({ isOpen: false, id: null })}
			classNameModal={styles.modal}
			closable={false}
			footer={() => (
				<div className={styles.buttons}>
					<Button
						onClick={() => {
							stateConfirmModal.id && tasks.setOrdersCollect(false, stateConfirmModal.id);
							setStateConfirmModal({ isOpen: false, id: null });
						}}
						type="primary"
					>
						Да
					</Button>
					<Button
						onClick={() => {
							stateConfirmModal.id && tasks?.setOrdersCollect(true, stateConfirmModal.id);
							setStateConfirmModal({ isOpen: false, id: null });
						}}
					>
						Нет
					</Button>
				</div>
			)}
			title=""
			centered
			open={stateConfirmModal.isOpen}
			destroyOnClose
		>
			Собрать все заказы?
		</Modal>
	);
};

export default ModalConfirmTypeCollect;
