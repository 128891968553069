import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { unpropagatedClickHandler } from 'utils/unpropagatedClickHandler';

import styles from './index.module.scss';

const CargoSpaces: FC = () => {
	const {
		print,
		shipment: {
			emall: { consolidation },
		},
	} = useStore();
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const handleSpaceClick = (): void => {
		setIsOpenMenu(false);
		consolidation.addSpace('Ящик');
	};

	const handleGoodsClick = (): void => {
		setIsOpenMenu(false);
		consolidation.modalCreateSpace.setShow(true);
	};

	return (
		<>
			<section className={styles.wrap}>
				<header className={styles.header}>
					<h2 className={styles.title}>Грузоместа</h2>
					<ContextMenu
						open={isOpenMenu}
						onOpenChange={(visible) => setIsOpenMenu(visible)}
						items={[
							{ text: 'Ящик', onClick: handleSpaceClick },
							{ text: 'КГТ', onClick: handleGoodsClick },
						]}
						trigger="click"
					>
						<IconButton imageName="Plus" stroke={colors.black} fontSize={16} />
					</ContextMenu>
				</header>
				{consolidation.spaces.length > 0 ? (
					<>
						<ul className={styles.list}>
							{consolidation.spaces.map(({ id, routing_items_count: count, type_name: name, type, status, number }) => {
								const isLock = status === 40;
								const isClickable = !isLock && type !== 4;

								return (
									<li
										key={id}
										className={cn(styles.item, { [styles.item__lock]: isLock, [styles.item__clickable]: isClickable })}
										onClick={() => isClickable && consolidation.loadSpace(id)}
										role="presentation"
									>
										<span className={styles.icon}>
											{type === 3 && (
												<Icon imageName={isLock ? 'Lock' : 'Unlock'} color={isLock ? colors.grey2 : colors.black} />
											)}
										</span>
										<span className={styles.name}>{name}</span>
										<span>{number ? number : (type === 3 && id.toString().slice(-3)) || ''}</span>
										<span>{count ? `${count} шт.` : ''}</span>
										<span style={{ width: 30 }}>
											{!isLock && count === 0 && (
												<IconButton
													className={styles.action}
													imageName="Close"
													color={colors.black}
													onClick={(evt) => {
														evt.stopPropagation();
														consolidation.removeSpace(id);
													}}
													disabled={!id}
												/>
											)}
											{isLock && type === 3 && (
												<IconButton
													className={styles.action}
													imageName="Print"
													onClick={unpropagatedClickHandler(() =>
														print.setPrintPreview({ type: 'routing-surplus', id })
													)}
													style={{ margin: '0 4px' }}
												/>
											)}
										</span>
									</li>
								);
							})}
						</ul>
						<Button
							className={styles.button}
							size="large"
							icon={<Icon imageName="Moving" />}
							onClick={() => consolidation.modalMoving.setShow(true)}
						>
							Замена пломбы
						</Button>
					</>
				) : null}
			</section>
		</>
	);
};

export default observer(CargoSpaces);
