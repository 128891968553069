/* eslint-disable @typescript-eslint/no-empty-interface */
// комменты оставил, так как это функционал будет возвращен
import { ImageName } from '@images/index';
import { AcceptanceService } from '@services/acceptance';
import { RootStore } from '@store/index';
import { cast, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { voiceMessage } from '../../../utils/voiceMessage';

const { string, boolean, optional, number, union, literal, model, maybeNull, array } = types;

const ItemPostal = model('item', {
	id: number,
	status: number,
	status_name: string,
	type: union(literal(0), literal(1)),
	is_scanned: union(literal(0), literal(1)),
	number: maybeNull(string),
	store_external_name: string,
	routing_items_count: number,
	acceptance_date: maybeNull(string),
	active_operator_id: maybeNull(number),
});

const PostalCargoSpacesItem = model('routing-space', {
	count_items: number,
	scanning_count_items: number,
	routing_spaces: array(ItemPostal),
});

const Item = model('item,', {
	id: number,
	number: string,
	is_scanned: union(literal(0), literal(1)),
	is_deleted: union(literal(0), literal(1)),
	message_error: optional(string, ''),
	store_place_item_full_name: maybeNull(string),
	key: maybeNull(number),
	status: maybeNull(number),
});

const ItemsModal = model('modal-items', {
	id: number,
	number: string,
	scanned_count: number,
	type: union(literal(0), literal(1)),
	scanning_status: number,
	count: number,
	routing_items: maybeNull(array(Item)),
});

const ItemCargoSpace = model('item-cargo-space', {
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	item: model('item-space', {
		id: maybeNull(number),
		number: string,
		message: maybeNull(string),
		status: number,
	}),
});

const PostalCargoSpaces = model('order', {
	postalCargoSpacesItems: maybeNull(PostalCargoSpacesItem),
	modalItems: maybeNull(ItemsModal),
	modalItemsSingle: maybeNull(array(Item)),
	isOpenModalItems: optional(boolean, false),
	infoScannedItem: maybeNull(ItemCargoSpace),
	contentModalInfo: maybeNull(string),
	isNewCell: optional(boolean, false),
	isNotFoundCargoSpaces: false,
})
	.actions((self) => {
		const root: any = getRoot<typeof RootStore>(self);

		return {
			loadPostalCargoSpaces: flow(function* () {
				root.setLoading(true);
				self.postalCargoSpacesItems = null;
				try {
					const res = yield AcceptanceService.getPostalCargoSpaces();
					self.postalCargoSpacesItems = res.data || null;
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanStorePlaceItems: flow(function* (number: string) {
				try {
					const res = yield AcceptanceService.postStorePlaceItemsScan({
						store_place_item_id_to_block: null,
						store_place_item_id_to_choose: Number(number),
						item_id: Number(self.infoScannedItem?.item.id || 0),
						routing_space_id: Number(self.modalItems?.id),
					});
					const item = {
						number: self.infoScannedItem?.item.number || '',
						is_scanned: 0,
						message_error: [2, 3, 4, 5].includes(res.data.item.status) ? res.data?.item.message || '' : '',
						id: Number(self.infoScannedItem?.item.id) || 0,
						store_place_item_full_name: res.data.store_place_item_full_name || '',
						is_deleted: 0,
						status: res.data.item.status,
					};

					return item;
				} catch (err: any) {
					voiceMessage({ message: err?.error || err?.message || '' });
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				}
			}),
			loadItemsForScan: flow(function* (number: string) {
				root.setLoading(true);
				self.isNotFoundCargoSpaces = false;
				try {
					if (number.includes('BY08')) {
						if (
							root.acceptance.postal?.postalCargoSpaces?.getModalItemsShared?.some(
								(el: any) => el.number === number && el.store_place_item_full_name
							)
						) {
							const fullNameCell = root.acceptance.postal?.postalCargoSpaces?.getModalItemsShared?.find(
								(el: any) => el.number === number
							)?.store_place_item_full_name;
							voiceMessage({ message: `Посылка уже принята, положите в ячейку ${fullNameCell}` });
							return;
						}

						const res = yield AcceptanceService.postPostalScanNumber(number);
						self.infoScannedItem = res.data;

						if (self.infoScannedItem && self.infoScannedItem.item.status) {
							self.contentModalInfo = self.infoScannedItem?.store_place_item_text || '';
							if ([3, 4, 5].includes(self.infoScannedItem?.item.status) && self.modalItems?.id) {
								const item: IItem = {
									number: self.infoScannedItem.item.number,
									is_scanned: 0,
									message_error: [3, 4, 5].includes(self.infoScannedItem.item.status)
										? self.infoScannedItem?.item.message || ''
										: '',
									id: Number(self.infoScannedItem.item.id) || 0,
									store_place_item_full_name: self.infoScannedItem.store_place_item_full_name || '',
									is_deleted: 0,
									status: self.infoScannedItem.item.status,
								};

								if (!self.modalItems?.routing_items?.some((el) => el.number === number)) {
									self.modalItemsSingle = cast(self.modalItemsSingle ? [...self.modalItemsSingle, item] : [item]);
									voiceMessage({ message: self.infoScannedItem?.item.message || '' });
									localStorage.setItem('voiceMessage', self.infoScannedItem?.item.message || '');
									return;
								}

								if (self.modalItems?.routing_items) {
									self.modalItems.routing_items = cast([
										...self.modalItems.routing_items.filter((el) => el.number !== self.infoScannedItem?.item.number),
										item,
									]);
								}
							}
							localStorage.setItem('voiceMessage', self.infoScannedItem?.item.message || '');
						}
						voiceMessage({ message: self.infoScannedItem?.item.message || '' });
					} else if (self.infoScannedItem) {
						// if (number === 'NewCell') {
						// 	self.isNewCell = true;
						// 	return;
						// }
						if (
							self.infoScannedItem.store_place_item_id &&
							String(self.infoScannedItem?.store_place_item_id) !== number.replace(/^0+/, '')
							// && !self.isNewCell
						) {
							voiceMessage({ message: 'Неверная ячейка', isSave: true });
							return;
						}

						const item = yield root.acceptance.postal.postalCargoSpaces?.scanStorePlaceItems(number || '');

						if (item) {
							if (
								!self.modalItems?.routing_items?.some((el) => el.number === self.infoScannedItem?.item.number) &&
								item
							) {
								if (Array.isArray(self.modalItemsSingle)) {
									self.modalItemsSingle = cast([...self.modalItemsSingle, item]);
								} else {
									self.modalItemsSingle = cast([item]);
								}
							} else if (self.modalItems?.routing_items && item) {
								self.modalItems.routing_items = cast([
									...self.modalItems.routing_items.filter((el) => el.number !== self.infoScannedItem?.item.number),
									item,
								]);
							} else {
								self.modalItems?.id && root.acceptance.postal.postalCargoSpaces.loadItems(self.modalItems?.id);
							}
							self.infoScannedItem = null;
							// self.isNewCell = false;
							self.contentModalInfo = null;
							voiceMessage({ message: 'Успешно', isSave: true });
						}
					} else {
						if (self.modalItems) {
							yield AcceptanceService.postSpacesPauseScanned(self.modalItems.id);
						}
						const res = yield AcceptanceService.postPostalScan({ number });
						if (res.data?.type === 1 && res.data?.scanning_status === 2) {
							self.isNotFoundCargoSpaces = true;
							return;
						}
						root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
						self.modalItems = res.data;
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			loadItems: flow(function* (id: number | string, status?: number): any {
				root.setLoading(true);
				try {
					if (status && [80, 81, 82].includes(status)) {
						const res = yield AcceptanceService.getPostalItems(id as number);
						self.modalItems = res.data;
						!self.isOpenModalItems ? (self.isOpenModalItems = true) : null;
					} else {
						const res = yield AcceptanceService.postSpacesStartScanned(id as number);
						yield root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
						self.modalItems = res.data;
						!self.isOpenModalItems ? (self.isOpenModalItems = true) : null;
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			setPauseScanned: flow(function* (id: number) {
				yield AcceptanceService.postSpacesPauseScanned(id);
				root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
			}),
			setStartScanned: flow(function* (id: number) {
				const res = yield AcceptanceService.postSpacesStartScanned(id as number);
				root.acceptance.postal.postalCargoSpaces?.loadPostalCargoSpaces();
				self.modalItems = res.data;
				self.isOpenModalItems = true;
			}),
			setIsOpenModalItems(value: boolean) {
				if (value === false) {
					self.modalItems = null;
					self.modalItemsSingle = null;
				}
				self.isOpenModalItems = value;
			},
			setCloseInfoModal() {
				self.infoScannedItem = null;
				self.contentModalInfo = null;
			},
		};
	})
	.views((self) => {
		const root = getRoot<typeof RootStore>(self);
		return {
			get getModalItemsShared(): IItem[] {
				const routingItems = (self.modalItems?.routing_items || []) as IItem[];
				const modalItemsSingle = (self.modalItemsSingle || []) as IItem[];
				return modalItemsSingle.concat(routingItems);
			},
			get getTotalItem(): number {
				return root.acceptance.postal.postalCargoSpaces.getModalItemsShared?.length || 0;
			},
			get scannedCount(): number {
				return (
					root.acceptance.postal.postalCargoSpaces.getModalItemsShared?.filter(
						(el) => [undefined, null, 1].includes(el.status) && el.store_place_item_full_name && !el.is_deleted
					)?.length || 0
				);
			},
			get getCounterError(): number {
				return root.acceptance.postal.postalCargoSpaces.getModalItemsShared?.filter((el) =>
					[3, 4, 5].includes(Number(el.status))
				).length;
			},
			get getCounterSurplus(): number {
				return root.acceptance.postal.postalCargoSpaces.getModalItemsShared?.filter((el) => el.status === 2).length;
			},
			get isSubmitDisable(): boolean {
				return (
					(!self?.modalItems && !self?.modalItemsSingle) ||
					(!root.acceptance.postal.postalCargoSpaces?.scannedCount &&
						!root.acceptance.postal.postalCargoSpaces?.getCounterSurplus)
				);
			},
			getIconName(value: number): ImageName {
				if ([80, 81].includes(value)) {
					return 'Lock';
				} else if (value === 82) {
					return 'Unlock';
				} else if (value === 83) {
					return 'PauseBgTransparent';
				} else {
					return 'PlayCircle';
				}
			},
		};
	});

export default PostalCargoSpaces;

export interface IItemsModal extends SnapshotIn<typeof ItemsModal> {}
export interface IItem extends SnapshotIn<typeof Item> {}
export interface IItemCargoSpace extends SnapshotIn<typeof ItemCargoSpace> {}
export interface IItemPostal extends SnapshotIn<typeof ItemPostal> {}
export interface IPostalCargoSpacesItem extends SnapshotIn<typeof PostalCargoSpacesItem> {}
