import Search from '@components/search';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useWindowSize } from '@hooks/useWindowSize';
import SearchService from '@services/search';
import { ConfigProvider } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Option from './components/option';
import styles from './index.module.scss';

let controller: AbortController;

const ClientSearch: FC = () => {
	const selectRef = useRef(null);
	const [options, setOptions] = useState<any[]>([]);
	const [value, setValue] = useState<string>();
	const [error, setError] = useState<string | null>(null);
	const [search, setSearch] = useState(false);
	const visit = useCurrentVisit();
	const { height } = useWindowSize();
	const { visit: visitKey } = useParams();

	const onSearch = async (value: string): Promise<void> => {
		if (!value.trim()) {
			setOptions([]);
			setValue('');
		}

		setError(null);
		setSearch(true);

		try {
			if (controller) controller.abort();
			controller = new AbortController();
			const res = await SearchService.findPostal(value, controller.signal);
			setOptions([
				...res.data.postalItems.map((postal) => ({
					value: String(postal.id),
					label: (
						<Option
							type={postal.type}
							number={postal.number}
							receiverName={postal.receiver_fio}
							receiverPhone={postal.receiver_phone}
							senderName={postal.sender_fio}
							senderPhone={postal.sender_phone}
							key={postal.id}
							selectString={value}
							externalId={postal.external_id}
							agreementTypeId={postal.agreement_type_id}
							marketStatus={postal.market_status_value}
						/>
					),
					item: postal,
				})),
				...res.data.orders.map((order) => ({
					value: String(order.id),
					label: (
						<Option
							key={order.id}
							type={3}
							number={order.market_id}
							receiverPhone={order.receiver_phone}
							receiverName={order.receiver_fio}
							selectString={value}
						/>
					),
					item: order,
				})),
			]);
		} catch (err: any) {
			if (err) {
				if (err.code !== 'ERR_CANCELED') {
					console.error('error', err);
					setOptions([]);
					setSearch(false);
				}
				//@ts-ignore
				if (err.message === 'canceled') return;
				setError(err.message || null);
			}
		} finally {
			setSearch(false);
		}
	};

	const onSelect = (id: string, option: any): void => {
		setValue('');
		setOptions([]);
		visit?.setPostalCollectScannedItems();
		visit?.clear();
		visit?.setStartLoadData({ id: +id, type: 'type' in option.item ? 'postal' : 'order' }, true);
		//@ts-ignore
		selectRef.current?.blur();
	};

	useEffect(() => {
		setOptions([]);
		setValue('');
		setError(null);
	}, [visitKey]);

	return (
		<ConfigProvider
			theme={{
				token: {
					boxShadowSecondary:
						'0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
				},
			}}
		>
			<Search
				maxLength={20}
				search={search}
				ref={selectRef}
				placeholder="Поиск"
				className={styles.select}
				options={options}
				showSearch
				onSearch={onSearch}
				value={value}
				onChange={setValue}
				onSelect={onSelect}
				listHeight={height - 270}
				dropdownStyle={{ padding: 0 }}
				notFoundContent={
					(error && <p className={styles.error}>{error}</p>) ||
					(!!value && <div style={{ textAlign: 'center', color: colors.grey1, padding: 16 }}>Ничего не найдено</div>)
				}
			/>
		</ConfigProvider>
	);
};

export default ClientSearch;
