import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ModalScannedCounter from '@pages/main/components/modal-scanned-counter';
import OrderService from '@services/order';
import { IOrderCollectItem } from '@store/visits/order-collect';
import { InputNumber, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const ModalOrders: FC = () => {
	const { print, notice, tasks, setLoading } = useStore();
	const [hiddenInputs, setHiddenInputs] = useState<string[]>([]);

	const onClose = (): void => {
		setHiddenInputs([]);
		tasks.closeModalOrderCollect();
	};

	const handleDoubleClick = (key: string): void => setHiddenInputs((prev) => [...prev, key]);

	const responseOrderItemScanManual = async (
		data: Pick<IOrderCollectItem, 'product_id' | 'price' | 'store_place_item_id' | 'quantity'> & { orders: number[] }
	): Promise<void> => {
		try {
			setLoading(true);
			await OrderService.postOrderScanManual(data);
			await tasks.setOrdersCollect(
				tasks.ordersCollect?.order_ids.length === 1,
				Number(tasks.ordersCollect?.order_ids[0])
			);
		} catch (err: any) {
			notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			setLoading(false);
		}
	};

	const responseItemsCollect = async (): Promise<void> => {
		try {
			setLoading(true);

			await OrderService.postCancelOrderCollect({
				order_ids: tasks.ordersCollect?.order_ids || [],
			});

			onClose();
		} catch (err: any) {
			notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			setLoading(false);
		}
	};

	const columns: ColumnsType<IOrderCollectItem> = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			render: (_, { name }) => <div>{name}</div>,
			width: 453,
		},
		{
			dataIndex: 'cell',
			title: 'Ячейка',
			render: (_, { store_place_full_name }) => <div>{store_place_full_name}</div>,
			width: 117,
		},
		{
			dataIndex: 'count',
			title: 'Кол-во в заказе',
			render: (_, { quantity }) => <div>{quantity}</div>,
			width: 133,
		},
		{
			dataIndex: 'price',
			title: 'Цена',
			render: (_, { price }) => <div>{price}</div>,
			width: 120,
		},
		{
			dataIndex: 'collected_quantity',
			title: 'Кол-во факт',
			render: (_, record) =>
				!hiddenInputs.includes(record.key || '') ? (
					<span>{record.collected_count}</span>
				) : (
					<InputNumber
						onChange={(value) =>
							responseOrderItemScanManual({
								orders: tasks.ordersCollect?.order_ids || [],
								price: record.price,
								quantity: Number(value),
								product_id: record.product_id,
								store_place_item_id: record.store_place_item_id,
							})
						}
						onBlur={() => setHiddenInputs(hiddenInputs.filter((el) => el !== record.key))}
						min={0}
						max={record.quantity}
						defaultValue={record.collected_count}
					/>
				),
			onCell: (record) => ({ onDoubleClick: () => record.key && handleDoubleClick(record.key) }),
			width: 120,
		},
		{
			dataIndex: 'final_price',
			title: 'Сумма',
			render: (_, { final_price }) => <div>{final_price}</div>,
			width: 88,
		},
	];

	return (
		<>
			<ModalScannedCounter
				classModal={styles.modal}
				onClose={onClose}
				totalCounter={tasks.ordersCollect?.count || 0}
				scannedCounter={tasks.ordersCollect?.collected_count || 0}
				description={tasks.ordersCollect?.receiver_fio || ''}
				title={
					<div className={styles.header}>
						<Icon imageName="ShoppingBagBlack" />
						<div>
							{tasks.ordersCollect?.order_ids?.length === 1
								? tasks.filteredOrders.find((el) => el.id === tasks.ordersCollect?.order_ids[0])?.market_id
								: 'Все заказы клиента'}
						</div>
					</div>
				}
				isShow={!!tasks.ordersCollect}
				footer={
					<div className={styles.footer}>
						<Button
							onClick={() =>
								print.setPrintPreview({
									type: 'order-for-collect',
									ids: tasks.ordersCollect?.order_ids.map((el) => el) || [],
								})
							}
						>
							Печать списка товаров
						</Button>
						<Button onClick={responseItemsCollect} type="primary">
							Завершить сборку
						</Button>
					</div>
				}
			>
				<Table
					pagination={false}
					className={styles.table}
					columns={columns}
					dataSource={tasks.ordersCollect?.items?.map((el, i) => ({ ...el, key: i.toString() })) || []}
					rowClassName={({ collected_count, quantity }: IOrderCollectItem) => {
						if (collected_count !== quantity && collected_count !== 0) return styles.yellow;
						if (quantity === collected_count) return styles.green;
						return '';
					}}
				/>
				<div className={styles.sum}>
					<div>Сумма заказа</div>
					<div className={styles.total_price}>{tasks.ordersCollect?.total_price}</div>
				</div>
			</ModalScannedCounter>
		</>
	);
};

export default observer(ModalOrders);
