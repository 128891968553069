import { AcceptanceService } from '@services/acceptance';
import { ShipmentService } from '@services/shipment';
import { cast, destroy, flow, getRoot, Instance, types } from 'mobx-state-tree';
import { voiceMessage } from 'utils/voiceMessage';

import { RootStore } from '../..';
import Product from './product';

const { model, number, string, array, maybeNull } = types;

const Surplus = model('surplus', {
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	item: model('item', {
		id: number,
		item_market_id: number,
		name: string,
		order_market_id: maybeNull(number),
		order_item_id: maybeNull(number),
	}),
});

const GoodsModel = model('goods-model', {
	items: maybeNull(array(Product)),
	modalAdd: false,
	surplusItems: maybeNull(array(Surplus)),
	isNewCell: false,
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		loadItems: flow(function* () {
			root.setLoading(true);
			self.items = null;

			try {
				const res = yield ShipmentService.getEmallItems();
				self.items = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		showModalAdd(value: boolean) {
			self.modalAdd = value;
		},
		deleteItem: flow(function* (id: number) {
			root.setLoading(true);
			try {
				yield ShipmentService.deleteEmallItem(id);
				root.shipment.emall.goods.loadItems();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setSurplusItems(items: SurplusItemType[] | null) {
			self.surplusItems = cast(items);
		},
		closeSurplusItem() {
			self.surplusItems && destroy(self.surplusItems[0]);
		},
		setIsNewCell(value: boolean) {
			self.isNewCell = value;
		},
		scanPlaceItemBarcode: flow(function* (barcode: number) {
			if (!self.surplusItems?.length) return;
			if (
				self.surplusItems[0].store_place_item_id &&
				self.surplusItems[0].store_place_item_id !== barcode &&
				!self.isNewCell
			) {
				voiceMessage({ message: 'Неверная ячейка', isSave: true });
				return;
			}

			const root = getRoot<typeof RootStore>(self);
			root.setLoading(true);

			const placeId: number | null = self.surplusItems[0].store_place_item_id
				? self.isNewCell
					? self.surplusItems[0].store_place_item_id
					: null
				: null;

			try {
				yield AcceptanceService.postEmallPlaceItemScan({
					store_place_item_id_to_block: placeId,
					store_place_item_id_to_choose: barcode,
					order_item_id: self.surplusItems[0].item.order_item_id,
					order_market_id: self.surplusItems[0].item.order_market_id,
				});

				self.isNewCell = false;
				root.shipment.emall.goods.closeSurplusItem();
				voiceMessage({ message: 'Успешно', isSave: true });
			} catch (err: any) {
				voiceMessage({ message: err?.message, isSave: true });
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
	};
});

export default GoodsModel;

export type SurplusItemType = Instance<typeof Surplus>;
