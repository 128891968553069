import Button from '@components/buttons/button';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC } from 'react';

import Header from './components/header';
import ItemsTable from './components/table';
import styles from './index.module.scss';

const ItemsProductModal: FC = () => {
	const {
		acceptance: {
			emall: { closeModalItems, modalItemsProduct },
		},
	} = useStore();

	return (
		<>
			<Modal
				title={
					<>
						<span>Приемка товаров Emall</span>
						{modalItemsProduct?.description && (
							<span className={styles.description}>{modalItemsProduct.description}</span>
						)}
						{!modalItemsProduct?.hasItems && !modalItemsProduct?.description && (
							<span className={styles.description}>Отсканируйте грузоместо</span>
						)}
					</>
				}
				okText="Завершить приемку"
				cancelText="Закрыть"
				open={!!modalItemsProduct}
				width={720}
				onCancel={closeModalItems}
				onOk={modalItemsProduct?.confirm}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
				okButtonProps={{ disabled: !modalItemsProduct?.hasItems }}
			>
				<Header />
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<ItemsTable />
				</OverlayScrollbarsComponent>
			</Modal>
			<Modal
				classNameModal={styles.info}
				className={styles.info__body}
				title={null}
				open={modalItemsProduct?.infoModal?.show}
				onCancel={modalItemsProduct?.closeInfoModal}
				hasLines={false}
				centered
				width={500}
				closable={false}
				footer={null}
			>
				<Icon className={styles.info__icon} imageName="Info" color={colors.grey2} fontSize={72} />
				<h3 className={styles.info__title}>
					Привязка товара заказа №{modalItemsProduct?.infoModal?.item.order_market_id}{' '}
					{modalItemsProduct?.infoModal?.store_place_item_id
						? `к ячейке ${modalItemsProduct.infoModal.store_place_item_full_name}`
						: `к новой ячейке`}
				</h3>
				<p className={styles.info__item}>
					{modalItemsProduct?.infoModal?.item.name}
					<br />
					Арт: {modalItemsProduct?.infoModal?.item.item_market_id}
				</p>
				{!modalItemsProduct?.infoModal?.store_place_item_id ? (
					<p className={styles.info__warn}>
						Необходимо отсканировать доступную статичную ячейку. При дальнейшем сканировании товаров данного заказа
						целевая ячейка будет предлагаться автоматически.
					</p>
				) : null}
				<Button className={styles.info__button} onClick={modalItemsProduct?.closeInfoModal}>
					Закрыть
				</Button>
			</Modal>
		</>
	);
};

export default observer(ItemsProductModal);
