import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const DeleteModal: FC = () => {
	const {
		shipment: {
			emall: {
				consolidation: { modalGoodsTable: modal },
			},
		},
	} = useStore();

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title={null}
			open={!!modal?.deleteId}
			onOk={modal?.submit}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
			footer={
				<Button className={styles.button} onClick={modal?.setDeleteId.bind(null, null)}>
					Закрыть
				</Button>
			}
		>
			<h3 className={styles.title}>Удаление</h3>
			<p className={styles.description}>Отсканируйте товар для подтверждения удаления</p>
		</Modal>
	);
};

export default observer(DeleteModal);
