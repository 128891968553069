import PdfModal from '@components/pdf-modal';
import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import cn from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import TasksHeader from './components/header';
import ModalPostals from './components/modal-postals';
import styles from './index.module.scss';

const Tasks: FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [noShadow, setNoShadow] = useState(true);
	const { tasks, loadTask } = useStore();

	useScanner(tasks.handleScanner);

	useEffect(() => {
		loadTask();
	}, []);

	return (
		<Layout>
			<Header className={cn(styles.header, { [styles.header__no_shadow]: noShadow })}>
				<TasksHeader />
			</Header>
			<Content>
				<OverlayScrollbarsComponent
					className={styles.scroll}
					options={{ scrollbars: { autoHide: 'move' } }}
					events={{
						scroll: () => {
							setNoShadow(!ref.current?.parentElement?.scrollTop);
						},
					}}
				>
					<div className={styles.scroll_wrap} ref={ref}>
						<Outlet />
					</div>
				</OverlayScrollbarsComponent>
			</Content>
			<ModalPostals />
			<PdfModal />
		</Layout>
	);
};

export default Tasks;
