/* eslint-disable @typescript-eslint/no-empty-interface */
import { ShipmentService } from '@services/shipment';
import { ShipmentPostalService } from '@services/shipment-postal';
import { cast, destroy, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';
import { sortRYG } from 'utils/sortRYG';

import { RootStore } from '../..';
import Postal, { IPostal } from './postal';

const { model, maybeNull, union, literal, array, string, number, optional } = types;

const Goods = model('goods', {
	id: number,
	name: string,
	market_id: number,
	count: optional(number, 1),
}).actions((self) => ({
	setCount(value: number | null) {
		value && (self.count = value);
	},
}));

const PostalSpace = model('modal-postal-space', {
	count: number,
	scanned_count: number,
	items: array(Postal),
	id: number,
	type: maybeNull(number),
	type_name: maybeNull(string),
	name: maybeNull(string),
	isTrouble: false,
})
	.actions((self) => ({
		setIsTrouble(value: boolean) {
			self.isTrouble = value;
		},
	}))
	.views((self) => ({
		get surplusCount() {
			return self.items.filter(({ isSurplus }) => isSurplus).length;
		},
		get scannedCount() {
			return self.items.filter(({ is_scanned: isScan, isSurplus }) => isScan && !isSurplus).length;
		},
		get scannedStaticCount() {
			return self.items.filter(({ routing_space_id: id, isSurplus }) => !id && !isSurplus).length;
		},
		get scannedAll() {
			return !self.items.some(({ is_scanned: isScan }) => !isScan);
		},
		get sortItems() {
			return self.items.slice().sort((a, b): -1 | 0 | 1 => {
				const root = getRoot<typeof RootStore>(self);
				const isStatic = root.shipment.post.consolidation.modalGoodsTable?.isStatic || false;
				const isScanA = isStatic && !self.isTrouble ? !a.routing_space_id : a.is_scanned || 0;
				const isScanB = isStatic && !self.isTrouble ? !b.routing_space_id : b.is_scanned || 0;

				return sortRYG([
					[!!a.wrong_status_type, !!b.wrong_status_type],
					[a.isSurplus, b.isSurplus],
					[!!isScanA, !!isScanB],
				]);
			});
		},
	}));

const ModalGoodsTable = model('modal-goods-table', {
	barcode: maybeNull(number),
	goods: maybeNull(array(Goods)),
	sign: '',
	space: maybeNull(PostalSpace),
	isStatic: false,
	showConfirm: false,
}).actions((self) => {
	let timeoutId: any;

	return {
		scanBarcodeTrouble: flow(function* (barcode: string, isWrong?: boolean) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет посылок' });
				throw 'Нет посылок';
			}

			root.setLoading(true);

			try {
				const item = self.space.items.find(({ number }) => number === barcode.toUpperCase());

				if (!item) {
					root.notice.setNotice({ message: 'Посылка не найдена' });
					return;
				}

				if (item.wrong_status_type === 1) {
					yield ShipmentPostalService.putPostalUnscan(item.id);
					item.update({ is_scanned: 1 });
				}

				if (item.wrong_status_type === 2) {
					yield ShipmentPostalService.postPostalTrouble(item.id);
					item.update({ is_scanned: 1 });
				}

				isWrong && destroy(item);
				root.shipment.post.consolidation.setModalWrong('');
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
				throw err?.error || err?.message || 'Ошибка';
			} finally {
				root.setLoading(false);
			}
		}),
		scheduleScanBarcode(barcode: string, isWrong?: boolean) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(this.scanBarcode.bind(null, barcode, isWrong), 500);
		},
		scanBarcode: flow(function* (barcode: string, isWrong?: boolean) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет посылок' });
				throw 'Нет посылок';
			}

			if (self.space.isTrouble) {
				root.shipment.post.consolidation.modalGoodsTable?.scanBarcodeTrouble(barcode);
				return;
			}

			if (isWrong) {
				const item = self.space.items.find(({ number }) => number === barcode);

				if (!item) {
					root.notice.setNotice({ message: 'Неверная посылка' });
					return;
				}

				item.wrong_status_type
					? root.shipment.post.consolidation.modalGoodsTable?.scanBarcodeTrouble(barcode, isWrong)
					: root.shipment.post.consolidation.modalGoodsTable?.removeItem(item.id);
				return;
			}

			root.setLoading(true);

			try {
				const item = self.space.items.find(({ number }) => number === barcode);

				const {
					data: { routing_item: postal, scanning_status: status, scanning_message: message },
				} = yield ShipmentPostalService.postPostalScan({
					number: barcode,
					[self.isStatic ? 'static_place_id' : 'routing_space_id']: self.space.id,
				});

				switch (status) {
					case 1:
						item ? item.update(postal) : self.space.items.push(postal);
						break;
					case 2:
						self.space.items.unshift({ ...postal, isSurplus: true });
						break;
					case 3:
						item ? item.update({ ...postal }) : self.space.items.unshift({ ...postal });
						break;

					default:
						root.notice.setNotice({ message });
						break;
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setBarcode(value: number | null) {
			self.barcode = value;
		},
		setGoods(value: IGoods[]) {
			self.goods = cast(value);
		},
		removeItem: flow(function* (id: number) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет товаров' });
				return;
			}

			root.setLoading(true);

			try {
				const item = self.space.items.find((item) => item.id === id);

				if (item) {
					yield ShipmentPostalService.putPostalUnscan(item.id);
					root.shipment.post.consolidation.setModalWrong('');
					destroy(item);
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setSign(value: string) {
			self.sign = value;
		},
		setTroubleSpace(items: IPostal[]) {
			if (!self.space) return;
			self.space.setIsTrouble(true);
			self.space = PostalSpace.create({
				...self.space,
				items: items.map((item) => ({ ...item, is_scanned: 0 })),
				type_name: 'Проблемные посылки',
			});
		},
		setShowConfirm(value: boolean) {
			self.showConfirm = value;
		},
		closeTrouble() {
			const root = getRoot<typeof RootStore>(self);
			self.space?.setIsTrouble(false);
			root.shipment.post.consolidation.loadSpace({ id: self.space?.id || null, isStatic: self.isStatic });
		},
		submitSpace: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			if (self.space?.isTrouble) {
				root.shipment.post.consolidation.modalGoodsTable?.closeTrouble();
				return;
			}

			if (!self.space?.id) {
				root.notice.setNotice({ message: 'Ошибка' });
				return;
			}

			root.setLoading(true);

			try {
				const { data } = self.isStatic
					? yield ShipmentPostalService.postStaticPlacesShip(self.space.id)
					: yield ShipmentPostalService.getPostalNonShippable(self.space.id);

				if (data.length) {
					root.shipment.post.consolidation.modalGoodsTable?.setTroubleSpace(data);
				} else {
					if (self.isStatic) {
						root.shipment.post.consolidation.modalGoodsTable?.close();
						return;
					}
					root.shipment.post.consolidation.modalGoodsTable?.setShowConfirm(true);
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		close() {
			if (self.space?.isTrouble) return this.closeTrouble();

			const root = getRoot<typeof RootStore>(self);
			root.shipment.post.consolidation.loadSpace(null);
			root.shipment.post.consolidation.loadSpaces();
		},
	};
});

const Space = model('space', {
	id: number,
	type: number,
	type_name: string,
	is_scanned: union(literal(0), literal(1)),
	number: maybeNull(string),
	routing_items_count: number,
	status: number,
});

const StaticSpace = model('space', {
	id: number,
	name: string,
	routing_items_count: number,
});

const Spaces = model('spaces', {
	dynamic_spaces: maybeNull(array(Space)),
	static_spaces: maybeNull(array(StaticSpace)),
}).views((self) => ({
	get disabledAdd() {
		if (!self.dynamic_spaces) return false;
		return self.dynamic_spaces.filter(({ status }) => status !== 40).length >= 3;
	},
}));

const ConsolidationModel = model('consolidation-model', {
	spaces: Spaces,
	modalGoodsTable: maybeNull(ModalGoodsTable),
	modalMoving: false,
	modalQr: maybeNull(number),
	modalWrong: '',
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		loadSpaces: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield ShipmentPostalService.getPostalSpaces();
				self.spaces = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		removeSpace: flow(function* (id: number) {
			root.setLoading(true);
			try {
				yield ShipmentService.deleteSpace(id);
				root.shipment.post.consolidation.loadSpaces();
				root.shipment.loadHeaderInfo();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadSpace: flow(function* (
			data: {
				id: number | null;
				isStatic?: boolean;
			} | null
		) {
			if (!data || !data.id) {
				self.modalGoodsTable = null;
				return;
			}

			const { id, isStatic = false } = data;
			root.setLoading(true);

			try {
				const res = yield ShipmentPostalService.getPostalSpaceItems(id, isStatic);
				const space = res.data;

				self.modalGoodsTable = ModalGoodsTable.create({ space, isStatic });
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		addSpace: flow(function* () {
			root.setLoading(true);
			try {
				yield ShipmentPostalService.postPostalSpaces();
				root.shipment.post.consolidation.loadSpaces();
				root.shipment.loadHeaderInfo();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		showModalMoving(value: boolean) {
			self.modalMoving = value;
		},
		setModalQr(value: number | null) {
			self.modalQr = value;
		},
		setModalWrong(value: string) {
			self.modalWrong = value;
		},
	};
});

export default ConsolidationModel;

export interface IGoods extends SnapshotIn<typeof Goods> {}
export interface IPostalSpace extends SnapshotIn<typeof PostalSpace> {}
export interface ISpaces extends SnapshotIn<typeof Spaces> {}
