export const soundNotification = async (isPositive: boolean): Promise<void> => {
	const audioCtx = new window.AudioContext();

	const notes = isPositive
		? [
				{ frequency: 659, duration: 0.1 },
				{ frequency: 880, duration: 0.15 },
		  ]
		: [{ frequency: 130, duration: 0.5 }];

	let currentTime = audioCtx.currentTime;

	const playNote = (note: { frequency: number; duration: number }): Promise<void> => {
		return new Promise((resolve) => {
			const oscillator = audioCtx.createOscillator();
			oscillator.type = 'square';
			oscillator.frequency.setValueAtTime(note.frequency, currentTime);
			oscillator.connect(audioCtx.destination);
			oscillator.start(currentTime);
			currentTime += note.duration;
			oscillator.stop(currentTime);
			oscillator.onended = () => resolve();
		});
	};
	for (const note of notes) {
		await playNote(note);
	}
	audioCtx.close();
};
