import Button from '@components/buttons/button';
import Modal from '@components/modal';
import PdfModal from '@components/pdf-modal';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { FC, useRef } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import VisitsHeader from './components';
import PassModal from './components/pass-modal';
import CreateIncident from './components/visit/compoments/incident-create-modal';
import CreateOrder from './components/visit/compoments/order-modal';
import styles from './index.module.scss';

const Visits: FC = () => {
	const store = useStore();
	const visit = useCurrentVisit();
	const { visit: visitNum } = useParams();
	const navigate = useNavigate();
	const confirmFooter = useRef<HTMLDivElement>(null);

	useScanner(store.visits.handleScanner);

	const handleCancelModal = (): void => {
		const visitNums = Array.from(store.visits.visitMap.keys());

		if (visitNums.length > 1 && visitNum) {
			store.visits.deleteVisit(visitNum);
			navigate(`/visits/${visitNums.filter((num) => num !== visitNum)[0]}`);
		}

		visit?.setConfirmModal(null);
	};

	return (
		<Layout>
			<Header className={styles.menu_header}>
				<VisitsHeader />
			</Header>
			<Content style={{ padding: 24 }}>
				<Outlet />
				<CreateOrder />
				<CreateIncident />
				<PdfModal />
				<Modal
					title={
						<div className={styles.modal__title}>
							<Icon imageName="CheckCircle" fontSize={72} color="#00C1A2" />
							<span>{visit?.confirmModal}</span>
						</div>
					}
					cancelText="Закрыть"
					onCancel={handleCancelModal}
					open={!!visit?.confirmModal}
					hasLines={false}
					centered
					width={416}
					footer={
						<div ref={confirmFooter}>
							<Button className={styles.modal__button} onClick={handleCancelModal}>
								Закрыть
							</Button>
						</div>
					}
					style={{ zIndex: 99999 }}
					afterOpenChange={(open) => open && confirmFooter.current?.querySelector('button')?.focus()}
					maskClosable={false}
				/>
				<PassModal />
			</Content>
		</Layout>
	);
};

export default observer(Visits);
