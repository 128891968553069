/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, string, maybeNull, union, literal } = types;

const Operator = model('User', {
	full_name: string,
	login: string,
	id: number,
	is_pos_enabled: false,
	store: model('Store', {
		id: number,
		name: string,
		access_settings: model('Access', {
			routing: union(literal(0), literal(1)),
			packing_acceptance: union(literal(0), literal(1)),
		}),
	}),
	call_center_phones: maybeNull(
		model('Phones', {
			evropochta: string,
			emall: string,
		})
	),
})
	.actions((self) => ({
		setPos(value: boolean) {
			self.is_pos_enabled = value;
		},
	}))
	.views((self) => ({
		get isEmallPackingMode() {
			return self.store.access_settings.packing_acceptance === 1;
		},
		get isRouting() {
			return self.store.access_settings.routing === 1;
		},
	}));

export default Operator;

export interface Operator extends SnapshotIn<typeof Operator> {}
