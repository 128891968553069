import { useStore } from '@hooks/useStore';
import { IProduct } from '@store/shipment/emall/product';
import { Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const GoodsTable: FC = () => {
	const {
		operator,
		shipment: { emall },
	} = useStore();

	const columns: ColumnsType<IProduct> = [
		{
			title: 'Дата',
			dataIndex: 'date',
			render: (_, { date }) => <span>{date}</span>,
			width: 136,
		},
		{
			title: 'Код товара',
			dataIndex: 'code',
			render: (_, { item_market_id: id }) => <span>{id}</span>,
			width: 112,
		},
		{
			title: 'Штрихкод',
			dataIndex: 'barcode',
			render: (_, { barcode }) => <span>{barcode || ''}</span>,
			width: 154,
		},
		{
			title: 'Название',
			dataIndex: 'name',
			render: (_, { name }) => <span>{name}</span>,
			width: 382,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			render: (_, { reason }) => (
				<span className={cn(styles.status, { [styles.status__inner]: reason === 'Отменен' })}>{reason}</span>
			),
			width: 110,
		},
		{
			title: 'Номер заказа',
			dataIndex: 'number',
			render: (_, { order_market_id: id }) => <span>{id || ''}</span>,
			width: 166,
		},
		operator?.isEmallPackingMode
			? {
					title: 'Пакеты',
					render: (_, { bags }) => (
						<div>
							{bags
								? bags.map((value) => (
										<Tag key={value} className={styles.tag}>
											{value}
										</Tag>
								  ))
								: null}
						</div>
					),
			  }
			: {},
		//Попросила Кристина скрыть
		// {
		// 	title: '',
		// 	render: (_, { reason, id }) => (
		// 		<div>
		// 			{reason === 'Излишек' ? (
		// 				<Button onClick={() => emall.goods.deleteItem(id)} className={styles.button__not_good}>
		// 					Товара нет
		// 				</Button>
		// 			) : null}
		// 		</div>
		// 	),
		// },
	];
	return (
		<Table
			rowKey="id"
			pagination={false}
			columns={columns}
			dataSource={emall.goods.items || []}
			onRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
			onHeaderRow={() => ({ className: cn(styles.row, styles.row__head) })}
		/>
	);
};

export default observer(GoodsTable);
