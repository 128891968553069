import { api } from '@http/index';
import { Postal } from '@models/index';
import { PostalTrackingModel } from '@models/postal-tracking';
import { IncidentItem } from '@store/visits/incidents';
import { PostalCalculate } from '@store/visits/postal';
import { RefusalReasonModel, RefusalReasonType } from '@store/visits/refusal-reasons';
import { AxiosResponse, GenericAbortSignal } from 'axios';

interface ComplaintType {
	id: number;
	postal_complaint_type_description: string;
	postal_complaint_type_id: number;
	type_id: number;
}

interface ComplaintSaveType {
	store_id: number | null;
	info: string;
	postal_complaint_type_id: number | null;
	postal_item_number: string | null;
	direction: number | null;
	postal_weight: number | null;
}

interface ComplaintEditType {
	info: string;
	postal_complaint_id: number | null;
	postal_weight: number | null;
}

export interface IPostalData {
	update_or_insert: boolean;
	height: number;
	width: number;
	length: number;
	inventory_tariff_sum: string | number;
	inventory_stuff_sum: string | number;
	fragile_tariff_sum: string | number;
	shipment_price_with_tax: number;
	oversize_tariff_sum: string | number;
	completeness_check_price: string | number;
	cash_on_delivery_declared_value_tariff_sum: string | number;
	weight_id: number;
	tariff_id: number;
	is_packing: boolean;
	is_inventory: number | null;
	is_fragile: number | null;
	is_oversize: number | null;
	is_completeness_check: number | null;
	is_relabeling: number | null;
	is_cash_on_delivery: 0 | 1 | null;
	cash_on_delivery_declared_value: number | null;
	cash_on_delivery_sum: string | number;
	cash_on_delivery_declared_value_sum: string | number;
	weight: number;
	cash_on_delivery_money_back_sum: string | number;
	cash_on_delivery_payer?: 0 | 1 | null;
	packaging_payer?: 0 | 1 | null;
	shipment_payer?: 0 | 1 | null;
}

export interface IPostalCalcData {
	is_inventory: number | null;
	is_fragile: number | null;
	is_oversize: number | null;
	is_completeness_check: number | null;
	is_relabeling: number | null;
	is_cash_on_delivery: 0 | 1 | null;
	is_cash_on_delivery_declared_value: number | null;
	cash_on_delivery_sum: string | number;
	cash_on_delivery_declared_value_sum: string | number;
	postal_weight: number | null;
	packing: {
		id: number;
		quantity: number;
	}[];
	cash_on_delivery_payer?: 0 | 1 | null;
	packaging_payer?: 0 | 1 | null;
	shipment_payer?: 0 | 1 | null;
}

export interface ReasonPostType {
	number?: string;
	ops_operators_id?: number;
	reason_id: number | null;
}

const getPostalInfo = (id: number): Promise<AxiosResponse<Postal>> => api.get<Postal>(`postal?id=${id}`);

const getPostalInfoByNumber = (number: string): Promise<AxiosResponse<Postal>> =>
	api.get<Postal>(`postal?number=${number}`);

const postalSave = (id: number, data: IPostalData): Promise<AxiosResponse<void>> =>
	api.post(`postal/${id}/accept`, data);

const getPostalTracking = (id: number): Promise<AxiosResponse<PostalTrackingModel[]>> =>
	api.get(`postal/tracking/${id}`);

const putPostalPayment = (id: number): Promise<AxiosResponse<PostalTrackingModel[]>> => api.put(`postal/${id}/payment`);

const getPostalCalc = (
	id: number,
	params: IPostalCalcData,
	signal: GenericAbortSignal
): Promise<AxiosResponse<PostalCalculate>> => api.get(`postal/${id}/payment/calculate`, { params, signal });

const packing = (
	id: number,
	data: {
		packing: {
			id: number;
			quantity: number;
			sum: string;
		}[];
	}
): Promise<AxiosResponse<void>> => api.post(`postal/${id}/packing`, data);

const postalCreate = (data: {
	delivery_type: number;
	weight: number;
	store_id_start: number;
	store_id_finish: number | null;
	receiver_market_address_id: number;
	receiver_name: string;
	receiver_surname: string;
	receiver_patronymic_name: string;
	receiver_phone_number: string;
	sender_id: number;
}): Promise<AxiosResponse<Postal>> => api.post<Postal>('/postal/create', data);

const getIncidents = (id: number): Promise<AxiosResponse<IncidentItem>> => api.get(`postal/complaint/${id}`);

const getPostalComplaintType = (): Promise<AxiosResponse<ComplaintType[]>> => api.get(`postal-complaint-type`);

const postalComplaintSave = (data: ComplaintSaveType): Promise<AxiosResponse<void>> =>
	api.post(`postal/complaint/`, data);

const postalComplaintEdit = (id: number, data: ComplaintEditType): Promise<AxiosResponse<void>> =>
	api.put(`postal/complaint/${id}`, data);

const getPostalReasons = (type: RefusalReasonType): Promise<AxiosResponse<RefusalReasonModel[]>> =>
	api.get(`postal/return-reason?type=${type}`);

const postalReasonPost = (data: ReasonPostType): Promise<AxiosResponse<void>> =>
	api.post(`postal/return-routing/`, data);

const postalCheck = (
	id: number,
	data: { phone_number: string } | { contractor_unn: string }
): Promise<AxiosResponse<void>> => api.post(`postal/${id}/check`, data);

const putPostal = (id: number, data: { store_id_start: number }): Promise<AxiosResponse<void>> =>
	api.put(`postal/${id}`, data);

const postPostalCollect = (postal_item_ids: number[]): Promise<AxiosResponse<void>> =>
	api.post(`postal/collect`, { postal_item_ids });

export const PostalService = {
	getPostalInfo,
	getPostalInfoByNumber,
	getPostalTracking,
	getPostalCalc,
	postalSave,
	postalCreate,
	getIncidents,
	getPostalComplaintType,
	postalComplaintSave,
	postalComplaintEdit,
	packing,
	putPostalPayment,
	getPostalReasons,
	postalReasonPost,
	postalCheck,
	putPostal,
	postPostalCollect,
};
