import Icon from '@images/index';
import { AutoComplete } from 'antd';
import React from 'react';

import styles from './index.module.scss';

type SearchProps = Parameters<typeof AutoComplete<any>>[0] & { search?: boolean };

const Search = React.forwardRef<any, SearchProps>(({ search, ...props }, ref) => (
	<div style={{ position: 'relative' }}>
		<AutoComplete {...props} ref={ref} />
		{search && <Icon imageName="Spinner" className={styles.spinner} />}
	</div>
));

export default Search;
